import React, { useState } from 'react';
import TeacherDiagnosesTable from './TeacherDiagnosesTable';
import { Input } from 'semantic-ui-react';
import { Button } from 'react-bootstrap';
import CaseItem from 'classes/CaseItem';
import { useHistory } from 'react-router-dom';
import Diagnosis from 'classes/Diagnosis';
import { ReduxState } from 'redux/reducers';
import { useSelector } from 'react-redux';
import ButtonModal from 'components/misc/ButtonModal';

interface TeacherOwnDiagnosesProps {
	caseItem: CaseItem;
}

const TeacherOwnDiagnoses = ({ caseItem }: TeacherOwnDiagnosesProps) => {
	const [name, setName] = useState('');
	const [hasError, setHasError] = useState(false);
	const history = useHistory();
	const user = useSelector((state: ReduxState) => state.authReducer.user);
	const diagnoses = useSelector((state: ReduxState) => state.diagnosisReducer.diagnoses.filter((d) => d.user?.id === user.id));

	const handleCreateDiagnosis = async () => {
		if (!name) return setHasError(true);
		const diagnosis = await Diagnosis.create({ name: name.toLowerCase() });
		history.push(`/admin/case/${caseItem.id}/diagnosis/${diagnosis.id}`);
	};

	const handlePickDiagnosis = async (diagnosisId: number) => {
		await CaseItem.addDiagnosis(caseItem.id, diagnosisId);
	};

	const handleChange = (value: string) => {
		setHasError(false);
		setName(value);
	};

	return (
		<div>
			<TeacherDiagnosesTable
				caseItem={caseItem}
				diagnoses={diagnoses}
				choices={(d) => (
					<>
						<Button onClick={() => history.push(`/admin/case/${caseItem.id}/diagnosis/${d.id}`)} variant="outline-secondary" size="sm">
							Rediger
						</Button>
						<Button
							style={{ marginLeft: '5px' }}
							size="sm"
							onClick={() => handlePickDiagnosis(d.id)}
							variant="outline-secondary"
							disabled={caseItem.diagnoses.some((diag) => diag.id === d.id)}
						>
							Vælg
						</Button>
					</>
				)}
			/>
			<ButtonModal
				variant="secondary"
				confirmButtonText="Opret ny diagnose"
				onConfirm={() => handleCreateDiagnosis()}
				openButtonText="Opret ny diagnose"
				title={`Opret diagnose ${name}`}
				block
			>
				<Input
					error={hasError}
					style={{ width: '100%' }}
					value={name}
					onChange={(e) => handleChange(e.target.value)}
					placeholder="Diagnose navn..."
				/>
			</ButtonModal>
		</div>
	);
};

export default TeacherOwnDiagnoses;
