import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CaseItem from 'classes/CaseItem';
import Diagnosis from 'classes/Diagnosis';
import Symptom from 'classes/Symptom';
import { CaseAction } from 'types/generated';
import Patient from 'classes/Patient';

interface TeacherBreadcrumbProps {
	caseItem?: CaseItem;
	diagnosis?: Diagnosis;
	symptom?: Symptom;
	action?: CaseAction;
	patient?: Patient;
}

const TeacherBreadcrumb = ({ caseItem, diagnosis, symptom, action, patient }: TeacherBreadcrumbProps) => {
	const history = useHistory();
	const active =
		(!!caseItem ? 1 : 0) + (!!diagnosis ? 1 : 0) + (!!symptom ? 1 : 0) + (!!action ? 1 : 0) + (!!patient ? 1 : 0);

	return (
		<Breadcrumb>
			{caseItem && (
				<Breadcrumb.Item active={active === 1} onClick={() => history.push(`/admin/case/${caseItem.id}`)}>
					{caseItem.name.toTitleCase()}
				</Breadcrumb.Item>
			)}
			{action && <Breadcrumb.Item active={active === 2}>{action.action.name?.toTitleCase()}</Breadcrumb.Item>}
			{patient && <Breadcrumb.Item active={active === 2}>{patient.id}</Breadcrumb.Item>}
			{diagnosis && (
				<Breadcrumb.Item
					onClick={() => history.push(`/admin/case/${caseItem.id}/diagnosis/${diagnosis.id}`)}
					active={active === 2}
				>
					{diagnosis.name.toTitleCase()}
				</Breadcrumb.Item>
			)}
			{symptom && (
				<Breadcrumb.Item
					onClick={() =>
						history.push(`/admin/case/${caseItem.id}/diagnosis/${diagnosis.id}/symptom/${symptom.id}`)
					}
					active={active === 3}
				>
					{symptom.name?.toTitleCase()}
				</Breadcrumb.Item>
			)}
		</Breadcrumb>
	);
};

export default TeacherBreadcrumb;
