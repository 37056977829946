import { gql } from 'apollo-boost';
import Apollo from './Apollo';
import store from 'redux/store';
import Patient from './Patient';
import { Case as CaseItemType, CaseInput, CaseActionInput } from 'types/generated';
import Action from './Action';
import Symptom from './Symptom';
import caseItemReducer from 'redux/reducers/caseItem.reducer';
import Diagnosis from './Diagnosis';

interface CaseItem extends CaseItemType {}

class CaseItem {
	static purefragment = gql`
		fragment CaseItemOnly on Case {
			id
				name
				opens
				closes
				token
				diagnoses {
					...Diagnosis
				}
				user{
					id
				}
			}
		${Diagnosis.fragment}
	`;

	static fragment = gql`
		fragment CaseItem on Case {
			id
			name
			opens
			closes
			token
			diagnoses {
				...Diagnosis
			}
			actions {
				id
				action {
					...Action
				}
				stepNumber
				template
				whitelisted {
					...Symptom
				}
			}
			patients {
				...Patient
			}
			user {
				id
			}
		}
		${Patient.fragment}
		${Action.fragment}
		${Symptom.fragment}
		${Diagnosis.fragment}
		
	`;

	static fetchAll = async () => {
		const query = gql`
			query Cases {
				cases {
					...CaseItem
				}
			}
			${CaseItem.fragment}
		`;

		store.dispatch(caseItemReducer.actions.clearCaseItems());
		const caseItems = await Apollo.query<CaseItem[]>('cases', query);
		store.dispatch(caseItemReducer.actions.addCaseItems(caseItems));
	};
	
	static fetchMine = async (user: any) => {
		const query = gql`
			query Cases {
				cases {
					...CaseItemOnly
				}
			}
			${CaseItem.purefragment}
		`;
		console.log("I got user",user);
		store.dispatch(caseItemReducer.actions.clearCaseItems());
		const caseItems = await Apollo.query<CaseItem[]>('cases', query );
		store.dispatch(caseItemReducer.actions.addCaseItems(caseItems));
	};

	static fetchOpen = async () => {
		const query = gql`
			query OpenCases {
				openCases {
					id
					name
				}
			}
		`;

		const caseItems = await Apollo.query<CaseItem[]>('openCases', query);
		store.dispatch(caseItemReducer.actions.clearCaseItems());
		store.dispatch(caseItemReducer.actions.addCaseItems(caseItems));
	};

	static fetchById = async (id: number) => {
		const query = gql`
			query Case($id: Int) {
				case(id: $id) {
					...CaseItem
				}
			}
			${CaseItem.fragment}
		`;

		const caseItem = await Apollo.query<CaseItem>('case', query, { id });
		store.dispatch(caseItemReducer.actions.addCaseItems(caseItem));
		return caseItem;
	};

	static create = async (data: Partial<CaseInput>) => {
		const mutation = gql`
			mutation($data: CaseInput) {
				createCase(data: $data) {
					...CaseItem
				}
			}
			${CaseItem.fragment}
		`;

		const caseItem = await Apollo.mutate<CaseItem>('createCase', mutation, { data });
		store.dispatch(caseItemReducer.actions.addCaseItems(caseItem));
		return caseItem;
	};

	static addDiagnosis = async (caseId: number, diagnosisId: number) => {
		const mutation = gql`
			mutation addDiagnosisToCase($caseId: Int, $diagnosisId: Int) {
				addDiagnosisToCase(caseId: $caseId, diagnosisId: $diagnosisId) {
					...CaseItem
				}
			}
			${CaseItem.fragment}
		`;

		const caseItem = await Apollo.mutate<CaseItem>('addDiagnosisToCase', mutation, {
			caseId,
			diagnosisId
		});

		store.dispatch(caseItemReducer.actions.addCaseItems(caseItem));
	};

	static removeDiagnosis = async (caseId: number, diagnosisId: number) => {
		const mutation = gql`
			mutation RemoveDiagnosisFromCase($caseId: Int, $diagnosisId: Int) {
				removeDiagnosisFromCase(caseId: $caseId, diagnosisId: $diagnosisId) {
					...CaseItem
				}
			}
			${CaseItem.fragment}
		`;

		const caseItem = await Apollo.mutate<CaseItem>('removeDiagnosisFromCase', mutation, { caseId, diagnosisId });
		store.dispatch(caseItemReducer.actions.addCaseItems(caseItem));
		return caseItem;
	};

	static delete = async (id: number) => {
		const mutation = gql`
			mutation DeleteCase($id: Int!) {
				deleteCase(id: $id)
			}
		`;

		await Apollo.mutate('deleteCase', mutation, { id });
		store.dispatch(caseItemReducer.actions.removeCaseItem(id));
	};

	static update = async (id: number, data: Partial<CaseInput>) => {
		const mutation = gql`
			mutation($id: Int!, $data: CaseInput) {
				updateCase(id: $id, data: $data) {
					...CaseItem
				}
			}
			${CaseItem.fragment}
		`;

		const caseItem = await Apollo.mutate<CaseItem>('updateCase', mutation, { id, data });
		store.dispatch(caseItemReducer.actions.addCaseItems(caseItem));
	};

	static addOrUpdateStep = async (data: Partial<CaseActionInput>) => {
		const mutation = gql`
			mutation AddOrUpdateCaseAction($data: CaseActionInput) {
				addOrUpdateCaseAction(data: $data) {
					...CaseItem
				}
			}
			${CaseItem.fragment}
		`;

		const caseItem = await Apollo.mutate<CaseItem>('addOrUpdateCaseAction', mutation, {
			data
		});
		store.dispatch(caseItemReducer.actions.addCaseItems(caseItem));
	};

	static deleteCaseAction = async (id: number) => {
		const mutation = gql`
			mutation DeleteCaseAction($id: Int) {
				deleteCaseAction(id: $id) {
					...CaseItem
				}
			}
			${CaseItem.fragment}
		`;

		const caseItem = await Apollo.mutate<CaseItem>('deleteCaseAction', mutation, {
			id
		});
		store.dispatch(caseItemReducer.actions.addCaseItems(caseItem));
	};
}

export default CaseItem;
