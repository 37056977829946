import { gql } from 'apollo-boost';
import { Step as StepType } from 'types/generated';
import Answer from './Answer';

interface Step extends StepType {}

class Step {
	static fragment = gql`
		fragment Step on Step {
			id
			content
			stepNumber
			action {
				id
				name
			}
			answers {
				...Answer
			}
		}
		${Answer.fragment}
	`;
}

export default Step;
