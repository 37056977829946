import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { Table } from 'semantic-ui-react';
import Action from 'classes/Action';
import { CaseAction } from 'types/generated';
import styled from 'styled-components';
import LoadingPage from 'components/misc/LoadingPage';

export interface ChosenSymptomActionsProps {
	caseId: number;
	caseAction: CaseAction;
}

const TableRow = styled.tr`
	cursor: pointer;

	:hover {
		background-color: #d3e2ed;
	}
`;

const ChosenSymptomActions: React.SFC<ChosenSymptomActionsProps> = ({ caseId, caseAction }) => {
	const caseItem = useSelector((state: ReduxState) => state.caseItemReducer.caseItems.find((c) => c.id === caseId));
	const diagnoses = useSelector((state: ReduxState) => state.diagnosisReducer.diagnoses);

	const handleChange = async (value: number) => {
		await Action.whitelistSymptom({ caseActionId: caseAction.id, symptomId: value });
	};

	if (diagnoses.length === 0) return <LoadingPage />;
	const caseSymptoms = caseItem.diagnoses
		.flatMap((d) => d.symptoms)
		.filter((value, index, self) => self.map((s) => s.symptom.id).indexOf(value.symptom.id) === index);
	const caseActionSymptoms = caseItem.actions.flatMap((a) => a.whitelisted);
	const remaining = caseSymptoms.filter((cs) => !caseActionSymptoms.map((cas) => cas.id).includes(cs.symptom.id));
	return (
		<Table size="small" style={{ width: '300px', margin: '0 auto auto 5px' }}>
			<Table.Header>
				<Table.HeaderCell textAlign="center">Ikke valgte symptomer</Table.HeaderCell>
			</Table.Header>
			<Table.Body>
				{remaining.map((s) => (
					<TableRow onClick={() => handleChange(s.symptom.id)}>
						<Table.Cell>{s.symptom.name.toTitleCase()}</Table.Cell>
					</TableRow>
				))}
				{remaining.length === 0 && (
					<TableRow>
						<Table.Cell textAlign="center">Alle symptomer er valgt</Table.Cell>
					</TableRow>
				)}
			</Table.Body>
		</Table>
	);
};

export default ChosenSymptomActions;
