import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import LoadingPage from 'components/misc/LoadingPage';
import CaseItem from 'classes/CaseItem';
import TeacherBreadcrumb from 'components/teacher/TeacherBreadcrumb';
import Diagnosis from 'classes/Diagnosis';
import Symptom from 'classes/Symptom';
import caseItemReducer from 'redux/reducers/caseItem.reducer';
import ActionTableDetails from './ActionTableDetails';
import CaseIntroDetails from './CaseIntroDetails';
import ActionButtonDetails from './ActionButtonDetails';
import { Divider } from 'semantic-ui-react';
import { Button } from 'react-bootstrap';

export interface ActionDetailsProps {}

const ActionDetails: React.SFC<ActionDetailsProps> = () => {
	const params = useParams<{ caseId: string; caseActionId: string; diagnosisId: string }>();
	const caseId = Number(params.caseId);
	const caseActionId = Number(params.caseActionId);
	const caseItem = useSelector((state: ReduxState) => state.caseItemReducer.caseItems.find((c) => c.id === caseId));
	const caseAction = useSelector((state: ReduxState) => caseItem?.actions.find((a) => a.id === caseActionId));
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		CaseItem.fetchById(caseId);
		Diagnosis.fetchAll();
		Symptom.fetchAll();
		if (!caseItem) {
			CaseItem.fetchById(caseId).then((c) => dispatch(caseItemReducer.actions.setSelectedCase(c)));
		}
	}, [caseId, caseItem, dispatch]);

	if (!caseItem) return <LoadingPage />;
	return (
		<div>
			<TeacherBreadcrumb caseItem={caseItem} action={caseAction} />
			{(caseAction.action.name === 'intro' || caseAction.action.name === 'input') && <CaseIntroDetails caseAction={caseAction} />}
			{caseAction.action.name === 'tabel' && <ActionTableDetails caseAction={caseAction} />}
			{caseAction.action.name === 'button' && <ActionButtonDetails caseAction={caseAction} />}
			<Divider hidden />
			<Button variant="outline-primary" block onClick={() => history.push(`/admin/case/${caseItem.id}`)}>
				Tilbage
			</Button>
		</div>
	);
};

export default ActionDetails;
