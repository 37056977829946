import React, { useState } from 'react';
import { Container, Segment, Form, Divider } from 'semantic-ui-react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import User from 'classes/User';
import * as Yup from 'yup';
import _ from 'lodash';
import { UserInput } from 'types/generated';
import { Button } from 'react-bootstrap';

export interface RegisterProps {
	onCancel: Function;
}

const validationSchema = Yup.object({
	username: Yup.string().min(3).required('Dette felt er krævet'),
	password: Yup.string().min(3).required('Dette felt er krævet'),
	email: Yup.string().email().required('Dette felt er krævet'),
	confirmPassword: Yup.string()
		.required('Dette felt er krævet')
		.oneOf([Yup.ref('password'), null], 'Kodeord skal være ens')
});

const Register: React.SFC<RegisterProps> = ({ onCancel }) => {
	const [registerLoading, setRegisterLoading] = useState(false);
	const history = useHistory();
	const formik = useFormik({
		initialValues: {
			username: '',
			email: '',
			password: '',
			confirmPassword: ''
		},
		onSubmit: (values) => handleSubmit(values),
		validationSchema,
		validateOnChange: false
	});

	const handleSubmit = async (values: UserInput) => {
		setRegisterLoading(true);
		values = _.pick(values, ['username', 'password', 'email']);
		await User.register(values);
		history.push('/');
	};

	return (
		<Container>
			<Segment>
				<h1 style={{ textAlign: 'center' }}>Opret ny bruger</h1>
				<Divider />
				<Form>
					<Form.Input
						name="username"
						label="Brugernavn"
						onChange={formik.handleChange}
						value={formik.values.username}
						error={formik.errors.username}
					/>
					<Form.Input label="Email" name="email" onChange={formik.handleChange} value={formik.values.email} error={formik.errors.email} />
					<Form.Input
						label="Kodeord"
						name="password"
						type="password"
						onChange={formik.handleChange}
						value={formik.values.password}
						error={formik.errors.password}
					/>
					<Form.Input
						label="Gentag kodeord"
						name="confirmPassword"
						type="password"
						onChange={formik.handleChange}
						value={formik.values.confirmPassword}
						error={formik.errors.confirmPassword}
					/>
					<Form.Button fluid loading={registerLoading} primary onClick={() => formik.handleSubmit()}>
						Registrér
					</Form.Button>
				</Form>
				<div style={{ height: '5px' }} />
				<Button block onClick={() => onCancel()} variant="outline-secondary">
					Annuller
				</Button>
				<div style={{ height: '5px' }} />
				<div style={{ textAlign: 'center' }}>
					<Button variant="outline-info" block onClick={() => history.push('/login')}>
						Har du allerede en bruger? Tryk her
					</Button>
				</div>
			</Segment>
		</Container>
	);
};

export default Register;
