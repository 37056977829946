import React, { useEffect, useState } from 'react';
import LoadingPage from 'components/misc/LoadingPage';
import { useHistory, useParams } from 'react-router-dom';
import Patient from 'classes/Patient';
import { CenterPage } from 'components/Frontpage';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import Login from 'components/auth/Login';

interface CaseLoaderProps {}

const CaseLoader = (props: CaseLoaderProps) => {
	const user = useSelector((state: ReduxState) => state.authReducer.user);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const params = useParams<{ token: string }>();
	const token = params.token;

	useEffect(() => {
		const generate = async () => {
			if (!user) return;
			try {
				setLoading(true);
				const p = await Patient.create({ token });
				history.push(`/patient/${p.id}`);
				setLoading(false);
			} catch (error) {
				setError('Casen blev ikke fundet. Er dit link korrekt?');
				setLoading(false);
			}
		};

		generate();
	}, [history, token, user]);

	if (loading) return <LoadingPage />;
	if (!user) return <Login />;
	if (error)
		return (
			<CenterPage>
				<p style={{ fontSize: '2em' }}>{error}</p>
				<Button size="lg" onClick={() => history.push('/')} variant="secondary">
					Gå til forsiden
				</Button>
			</CenterPage>
		);
	return <LoadingPage />;
};

export default CaseLoader;
