import { gql } from 'apollo-boost';
import Apollo from './Apollo';
import store from 'redux/store';
import { SymptomText as SymptomTextType, SymptomTextInput, TryTextInput } from 'types/generated';
import symptomReducer from 'redux/reducers/symptom.reducer';

interface SymptomText extends SymptomTextType {}

class SymptomText {
	static fragment = gql`
		fragment SymptomText on SymptomText {
			id
			text
			exampleText
			symptoms {
				id
				name
			}
		}
	`;

	static fetchAll = async () => {
		const query = gql`
			query {
				symptomTexts {
					...SymptomText
				}
			}
			${SymptomText.fragment}
		`;

		const symptomTexts = await Apollo.query<SymptomText[]>('symptomTexts', query);
		store.dispatch(symptomReducer.actions.addSymptomTexts(symptomTexts));
	};

	static create = async (data: SymptomTextInput) => {
		const mutation = gql`
			mutation($data: SymptomTextInput) {
				createSymptomText(data: $data) {
					...SymptomText
				}
			}
			${SymptomText.fragment}
		`;

		const symptomText = await Apollo.mutate<SymptomText>('createSymptomText', mutation, { data });
		store.dispatch(symptomReducer.actions.addSymptomTexts(symptomText));
		return symptomText;
	};

	static addSymptom = async (data: { symptomIds: number[]; symptomTextId: number }) => {
		const mutation = gql`
			mutation($data: SymptomToSymptomTextInput) {
				addSymptomToSymptomText(data: $data) {
					...SymptomText
				}
			}
			${SymptomText.fragment}
		`;

		const symptomText = await Apollo.mutate<SymptomText>('addSymptomToSymptomText', mutation, {
			data
		});
		store.dispatch(symptomReducer.actions.addSymptomTexts(symptomText));
	};

	static remove = async (id: number) => {
		const mutation = gql`
			mutation($id: Int!) {
				deleteSymptomText(id: $id)
			}
		`;
		await Apollo.mutate('deleteSymptom', mutation, { id });
		store.dispatch(symptomReducer.actions.removeSymptomText(id));
	};

	static update = async (id: number, data: Partial<SymptomTextInput>) => {
		const mutation = gql`
			mutation($id: Int!, $data: UpdateSymptomTextInput) {
				updateSymptomText(id: $id, data: $data) {
					...SymptomText
				}
			}
			${SymptomText.fragment}
		`;

		const symptomText = await Apollo.mutate<SymptomText>('updateSymptomText', mutation, {
			id,
			data
		});
		store.dispatch(symptomReducer.actions.addSymptomTexts(symptomText));
	};

	static try = async (data: TryTextInput) => {
		const query = gql`
			query TryText($data: TryTextInput) {
				tryText(data: $data)
			}
		`;

		const triedText = await Apollo.query<string>('tryText', query, { data });
		return triedText;
	};
}

export default SymptomText;
