import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
	guideStep: 0,
	diagnosesTabIndex: 0
};

const utilsReducer = createSlice({
	name: 'utils',
	initialState,
	reducers: {
		changeGuideStep: (state, action: PayloadAction<number> | null) => {
			state.guideStep = action.payload;
		},
		changeDiagnosesTabIndex: (state, action: PayloadAction<number>) => {
			state.diagnosesTabIndex = action.payload;
		}
	}
});

export default utilsReducer;
