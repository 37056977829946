import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Symptom from 'classes/Symptom';
import { insertOrReplace, removeFromState } from 'redux/misc/utility';
import Category from 'classes/Category';
import Perspective from 'classes/Perspective';
import SymptomText from 'classes/SymptomText';
import SymptomType from 'classes/SymptomType';

const initialState = {
	symptoms: [] as Symptom[],
	categories: [] as Category[],
	perspectives: [] as Perspective[],
	symptomTexts: [] as SymptomText[],
	symptomTypes: [] as SymptomType[]
};

const symptomReducer = createSlice({
	name: 'symptom',
	initialState,
	reducers: {
		addSymptoms: (state, action: PayloadAction<Symptom | Symptom[]>) => {
			insertOrReplace(state.symptoms, action.payload);
		},
		removeSymptom: (state, action: PayloadAction<number>) => {
			removeFromState(state.symptoms, action.payload);
		},
		addSymptomTypes: (state, action: PayloadAction<SymptomType | SymptomType[]>) => {
			insertOrReplace(state.symptomTypes, action.payload);
		},
		removeSymptomTypes: (state, action: PayloadAction<number>) => {
			removeFromState(state.symptomTypes, action.payload);
		},
		addSymptomTexts: (state, action: PayloadAction<SymptomText | SymptomText[]>) => {
			insertOrReplace(state.symptomTexts, action.payload);
		},
		removeSymptomText: (state, action: PayloadAction<number>) => {
			removeFromState(state.symptomTexts, action.payload);
		},
		addCategories: (state, action: PayloadAction<Category | Category[]>) => {
			insertOrReplace(state.categories, action.payload);
		},
		addPerspectives: (state, action: PayloadAction<Perspective | Perspective[]>) => {
			insertOrReplace(state.perspectives, action.payload);
		}
	}
});

export default symptomReducer;
