import React, { useContext, useState } from 'react';
import { StyledParagraph } from 'components/layout/styled/text';
import { Button } from 'semantic-ui-react';
import { StepContext } from 'components/patient/Patient';
import Answer from 'classes/Answer';
import marked from 'marked';

export interface CaseIntroProps {
	content: string;
}

const CaseIntro: React.SFC<CaseIntroProps> = ({ content: value }) => {
	const [loading, setLoading] = useState(false);
	const { step, currentStep } = useContext(StepContext);
	const isAnswered = step.answers.some((a) => !!a.text);

	const handleAnswer = async () => {
		setLoading(true);
		await Answer.answer({ stepId: step.id, text: '1' });
		setLoading(false);
	};

	return (
		<div>
			<StyledParagraph>
				<div dangerouslySetInnerHTML={{ __html: marked(value, { smartypants: true }) }} />
			</StyledParagraph>
			{!isAnswered && (
				<Button
					loading={loading}
					disabled={step.stepNumber < currentStep || loading}
					basic
					color="blue"
					fluid
					onClick={handleAnswer}
				>
					Videre
				</Button>
			)}
		</div>
	);
};

export default CaseIntro;
