import { gql } from 'apollo-boost';
import Apollo from './Apollo';
import store from 'redux/store';
import { Symptom as SymptomType, SymptomInput, SymptomParameterInput, SymptomSymptomTextInput } from 'types/generated';
import symptomReducer from 'redux/reducers/symptom.reducer';
import Parameter from './Parameter';

interface Symptom extends SymptomType {}

class Symptom {
	static fragment = gql`
		fragment Symptom on Symptom {
			id
			name
			symptomType {
				id
				name
			}
			perspective {
				id
				name
			}
			category {
				id
				name
			}
			user {
				id
			}
			symptomTexts {
				precision
				symptomText {
					id
					text
				}
			}
			parameters {
				parameter {
					...Parameter
				}
				min
				max
			}
		}
		${Parameter.fragment}
	`;

	static create = async (data: SymptomInput) => {
		const mutation = gql`
			mutation($data: SymptomInput) {
				createSymptom(data: $data) {
					...Symptom
				}
			}
			${Symptom.fragment}
		`;

		const symptom = await Apollo.mutate<Symptom>('createSymptom', mutation, { data });
		store.dispatch(symptomReducer.actions.addSymptoms(symptom));
		return symptom;
	};

	static addParameter = async (data: SymptomParameterInput) => {
		const mutation = gql`
			mutation($data: SymptomParameterInput) {
				addParameterToSymptom(data: $data) {
					...Symptom
				}
			}
			${Symptom.fragment}
		`;

		const symptom = await Apollo.mutate<Symptom>('addParameterToSymptom', mutation, { data });
		store.dispatch(symptomReducer.actions.addSymptoms(symptom));
	};

	static fetchAll = async () => {
		const query = gql`
			{
				symptoms {
					...Symptom
				}
			}
			${Symptom.fragment}
		`;

		const symptoms = await Apollo.query<Symptom[]>('symptoms', query);
		store.dispatch(symptomReducer.actions.addSymptoms(symptoms));
	};

	static fetchById = async (id: number) => {
		const query = gql`
			query Symptom($id: Int!) {
				symptom(id: $id) {
					...Symptom
				}
			}
			${Symptom.fragment}
		`;

		const symptom = await Apollo.query<Symptom>('symptom', query, { id });
		store.dispatch(symptomReducer.actions.addSymptoms(symptom));
		return symptom;
	};

	static remove = async (id: number) => {
		const mutation = gql`
			mutation($id: Int!) {
				deleteSymptom(id: $id)
			}
		`;

		await Apollo.mutate('deleteSymptom', mutation, { id });
		store.dispatch(symptomReducer.actions.removeSymptom(id));
	};

	static removeParameter = async (parameterId: number, symptomId: number) => {
		const mutation = gql`
			mutation($parameterId: Int!, $symptomId: Int!) {
				removeParameterFromSymptom(parameterId: $parameterId, symptomId: $symptomId) {
					...Symptom
				}
			}
			${Symptom.fragment}
		`;

		const symptom = await Apollo.mutate<Symptom>('removeParameterFromSymptom', mutation, {
			parameterId,
			symptomId
		});
		store.dispatch(symptomReducer.actions.addSymptoms(symptom));
	};

	static update = async (id: number, data: Partial<SymptomInput>) => {
		const mutation = gql`
			mutation($id: Int!, $data: UpdateSymptomInput) {
				updateSymptom(id: $id, data: $data) {
					...Symptom
				}
			}
			${Symptom.fragment}
		`;

		const symptom = await Apollo.mutate<Symptom>('updateSymptom', mutation, { id, data });
		store.dispatch(symptomReducer.actions.addSymptoms(symptom));
	};

	static addSymptomType = async (data: { symptomId: number; symptomTypeId: number }) => {
		const mutation = gql`
			mutation($data: SymptomTypeSymptomInput) {
				addSymptomTypeToSymptom(data: $data) {
					...Symptom
				}
			}
			${Symptom.fragment}
		`;

		const symptom = await Apollo.mutate<Symptom>('addSymptomTypeToSymptom', mutation, { data });
		store.dispatch(symptomReducer.actions.addSymptoms(symptom));
	};

	static addSymptomText = async (data: Partial<SymptomSymptomTextInput>) => {
		const mutation = gql`
			mutation($data: SymptomSymptomTextInput) {
				addSymptomTextToSymptom(data: $data) {
					...Symptom
				}
			}
			${Symptom.fragment}
		`;

		const symptom = await Apollo.mutate<Symptom>('addSymptomTextToSymptom', mutation, { data });
		store.dispatch(symptomReducer.actions.addSymptoms(symptom));
	};

	static removeSymptomText = async (data: { symptomTextId: number; symptomId: number }) => {
		const mutation = gql`
			mutation($data: SymptomSymptomTextInput) {
				removeSymptomTextFromSymptom(data: $data) {
					...Symptom
				}
			}
			${Symptom.fragment}
		`;

		const symptom = await Apollo.mutate<Symptom>('removeSymptomTextFromSymptom', mutation, {
			data
		});
		store.dispatch(symptomReducer.actions.addSymptoms(symptom));
	};
}

export default Symptom;
