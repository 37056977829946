import React, { useState } from 'react';
import Diagnosis from 'classes/Diagnosis';
import { Link, useHistory } from 'react-router-dom';
import CaseItem from 'classes/CaseItem';
import { Table } from 'react-bootstrap';
import { Input } from 'semantic-ui-react';

export interface TeacherDiagnosesTableProps {
	diagnoses: Diagnosis[];
	caseItem: CaseItem;
	choices?: (d: Diagnosis) => any;
}

const TeacherDiagnosesTable: React.SFC<TeacherDiagnosesTableProps> = ({ diagnoses, caseItem, choices }) => {
	const history = useHistory();
	const [search, setSearch] = useState('');

	return (
		<Table bordered>
			<thead>
				<tr>
					<th colSpan={3}>
						<Input fluid placeholder="Søg..." onChange={(e) => setSearch(e.target.value)} value={search} />
					</th>
				</tr>
				<tr>
					<th>Navn</th>
					<th>Antal symptomer</th>
					{!!choices && <th>Valg</th>}
				</tr>
			</thead>
			<tbody>
				{diagnoses
					.filter((d) => d.name.includes(search))

					.map((d) => (
						<tr key={d.id}>
							<td>
								<Link to={`${history.location.pathname}/diagnosis/${d.id}`}>{d.name}</Link>
							</td>
							<td>{d.symptoms.length}</td>
							{!!choices && <td>{choices(d)}</td>}
						</tr>
					))}
				{diagnoses.length === 0 && (
					<tr>
						<td colSpan={3}>Der er ingen diagnoser...</td>
					</tr>
				)}
			</tbody>
		</Table>
	);
};

export default TeacherDiagnosesTable;
