import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { Button, Modal } from 'react-bootstrap';
import { Divider } from 'semantic-ui-react';
import PatientTable from 'components/teacher/PatientTable';
import { StyledContainer } from './TeacherApp';
import { useHistory, Link } from 'react-router-dom';
import CaseItem from 'classes/CaseItem';
import ButtonLoading from 'components/misc/ButtonLoading';
import { FlexRow } from './Guide';
import utilsReducer from 'redux/reducers/utils.reducer';
import ButtonModal from 'components/misc/ButtonModal';
import ButtonModalCheckbox from 'components/misc/ButtonModalCheckbox';

interface CaseOverviewProps {}

const CaseOverview = (props: CaseOverviewProps) => {
	const history = useHistory();
	const [isDeleting, setIsDeleting] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [modalConfirming, setModalConfirming] = useState(false);
	const caseItem = useSelector((state: ReduxState) => state.caseItemReducer.selectedCase);
	const dispatch = useDispatch();

	const handleDelete = async () => {
		setDeleteLoading(true);
		await CaseItem.delete(caseItem.id);
		history.push('/admin');
	};

	return (
		<StyledContainer>
			<Modal show={isDeleting} onHide={() => setIsDeleting(false)}>
				<Modal.Header>
					<Modal.Title></Modal.Title>
				</Modal.Header>
				<Modal.Footer>
					<ButtonLoading onClick={handleDelete} loading={deleteLoading} variant="danger">
						Ja, set casen
					</ButtonLoading>
					<Button onClick={() => setIsDeleting(false)} variant="outline-secondary">
						Annuller
					</Button>
				</Modal.Footer>
			</Modal>
			<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
				<h1>{caseItem.name}</h1>
				<div>
					<Button style={{ whiteSpace: 'nowrap' }} variant="outline-secondary">
						Rediger navn
					</Button>
					<ButtonModal
						style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}
						variant="outline-danger"
						onConfirm={handleDelete}
						confirmButtonText="Slet casen"
						title={`Er du sikker på, at du vil slette casen "${caseItem.name}"?`}
						openButtonText="Slet"
						confirmDisabled={!modalConfirming}
					>
						<ButtonModalCheckbox checked={modalConfirming} onChange={(v) => setModalConfirming(v)}>
							Casen vil ikke kunne gendannes. Jeg bekræfter at jeg er indforstået med dette.
						</ButtonModalCheckbox>
					</ButtonModal>
				</div>
			</div>
			<Divider />
			<div style={{ textAlign: 'center', fontSize: '1.3em' }}>
				<p>
					Link til oprettelse af patient: <Link to={`/case/${caseItem.token}`}>https://dynamiskecases.au.dk/case/{caseItem.token}</Link>
				</p>
			</div>
			<Divider />
			<PatientTable />
			<Divider />
			<FlexRow>
				<Button onClick={() => dispatch(utilsReducer.actions.changeGuideStep(0))} variant="outline-secondary">
					Tilbage
				</Button>
				<Button onClick={() => history.push('/admin')} variant="primary" size="lg">
					Færdig
				</Button>
			</FlexRow>
		</StyledContainer>
	);
};

export default CaseOverview;
