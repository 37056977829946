import React from 'react';
import TeacherDiagnosesTable from './TeacherDiagnosesTable';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import CaseItem from 'classes/CaseItem';
import { Button } from 'react-bootstrap';

interface TeacherPublicDiagnosesProps {
	caseItem: CaseItem;
}

const TeacherPublicDiagnoses = ({ caseItem }: TeacherPublicDiagnosesProps) => {
	const diagnoses = useSelector((state: ReduxState) =>
		state.diagnosisReducer.diagnoses.filter((d) => !d.user && !caseItem.diagnoses.some((diag) => diag.id === d.id))
	);

	const handlePickDiagnosis = async (diagnosisId: number) => {
		await CaseItem.addDiagnosis(caseItem.id, diagnosisId);
	};

	return (
		<TeacherDiagnosesTable
			caseItem={caseItem}
			diagnoses={diagnoses}
			choices={(d) => (
				<Button size="sm" onClick={() => handlePickDiagnosis(d.id)} variant="outline-secondary">
					Vælg
				</Button>
			)}
		/>
	);
};

export default TeacherPublicDiagnoses;
