import React, { useState, useContext } from 'react';
import { Table } from 'react-bootstrap';
import Answer from 'classes/Answer';
import { StepContext } from './Patient';
import { Button } from 'semantic-ui-react';

export interface CaseTableProps {
	content: string;
}

const CaseTable: React.SFC<CaseTableProps> = ({ content }) => {
	const values: { label: string; value: any }[] = JSON.parse(content);
	const [loading, setLoading] = useState(false);
	const { step, currentStep } = useContext(StepContext);
	const isAnswered = step.answers.some((a) => !!a.text);

	const handleAnswer = async () => {
		setLoading(true);
		await Answer.answer({ stepId: step.id, text: '1' });
		setLoading(false);
	};

	return (
		<div>
			<Table bordered>
				<thead>
					<tr>
						<th>Parameter</th>
						<th>Værdi</th>
					</tr>
				</thead>
				<tbody>
					{values.map((v) => (
						<tr>
							<td>{v.label}</td>
							<td>{v.value}</td>
						</tr>
					))}
				</tbody>
			</Table>
			{!isAnswered && (
				<Button
					loading={loading}
					disabled={step.stepNumber < currentStep || loading}
					basic
					color="blue"
					fluid
					onClick={handleAnswer}
				>
					Videre
				</Button>
			)}
		</div>
	);
};

export default CaseTable;
