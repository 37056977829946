import React, { useState, useEffect } from 'react';
import Symptom from 'classes/Symptom';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import Select from 'components/misc/Select';
import { Button } from 'react-bootstrap';
import SymptomText from 'classes/SymptomText';

export interface SymptomTextToSymptomInputProps {
	symptomId: number;
}

const SymptomTextToSymptomInput: React.SFC<SymptomTextToSymptomInputProps> = ({ symptomId }) => {
	const symptomTexts = useSelector((state: ReduxState) => state.symptomReducer.symptomTexts);
	const [symptomTextId, setSymptomTextId] = useState<number>();

	const handleSubmit = async () => {
		const data = { symptomId, symptomTextId: symptomTextId };
		await Symptom.addSymptomText(data);
		setSymptomTextId(undefined);
	};

	const handleChange = (value: number) => {
		setSymptomTextId(value);
	};

	useEffect(() => {
		SymptomText.fetchAll();
	}, []);

	const options = symptomTexts.map((st) => ({
		label: st.text,
		value: String(st.id)
	}));
	return (
		<div style={{ display: 'grid', gridTemplateColumns: '80% 20%' }}>
			<Select
				value={symptomTextId}
				onChange={handleChange}
				options={options}
				placeholder="Vælg symptom tekst"
				showSearch
			/>
			<Button onClick={handleSubmit} variant="outline-primary">
				Tilføj
			</Button>
		</div>
	);
};

export default SymptomTextToSymptomInput;
