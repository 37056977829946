import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { GuideText, StyledDivider, FlexRow } from './Guide';
import Diagnosis from 'classes/Diagnosis';
import { Divider } from 'semantic-ui-react';
import TeacherPublicDiagnoses from './TeacherPublicDiagnoses';
import TeacherCopyDiagnoses from './TeacherCopyDiagnoses';
import TeacherOwnDiagnoses from './TeacherOwnDiagnoses';
import TeacherChosenDiagnoses from './TeacherChosenDiagnoses';
import { Button, Tabs, Tab } from 'react-bootstrap';
import utilsReducer from 'redux/reducers/utils.reducer';

export interface GuideDiagnosesProps {}

const GuideDiagnoses: React.SFC<GuideDiagnosesProps> = () => {
	const caseItem = useSelector((state: ReduxState) => state.caseItemReducer.selectedCase);
	const { diagnosesTabIndex } = useSelector((state: ReduxState) => state.utilsReducer);
	const dispatch = useDispatch();

	useEffect(() => {
		Diagnosis.fetchAll();
	}, []);

	return (
		<div>
			<h1 style={{ textAlign: 'center' }}>{caseItem.name}</h1>
			<StyledDivider />
			<GuideText>
				Vælg de diagnoser som skal indgå i din case. Jo flere, desto mere dynamisk, men også sværere for brugerne. Eksisterer din sygdom ikke?
				Kontakt os, så vil vi hjælpe dig igennem at oprette en diagnose.
			</GuideText>
			<Divider />
			<Tabs id="tabs" activeKey={diagnosesTabIndex} onSelect={(key) => dispatch(utilsReducer.actions.changeDiagnosesTabIndex(key))}>
				<Tab eventKey={0} title="System">
					<TeacherPublicDiagnoses caseItem={caseItem} />
				</Tab>
				<Tab eventKey={1} title="Egne">
					<TeacherOwnDiagnoses caseItem={caseItem} />
				</Tab>
				<Tab eventKey={2} title="Kopier">
					<TeacherCopyDiagnoses caseItem={caseItem} />
				</Tab>
			</Tabs>
			<Divider />
			<TeacherChosenDiagnoses caseItem={caseItem} />
			<Divider />
			<FlexRow>
				<Button
					onClick={() => dispatch(utilsReducer.actions.changeGuideStep(1))}
					disabled={caseItem.diagnoses.length === 0}
					variant="outline-secondary"
				>
					Videre
				</Button>
			</FlexRow>
		</div>
	);
};

export default GuideDiagnoses;
