import React, { useEffect, useState } from 'react';
import { GuideText, StyledDivider } from './Guide';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import DiagnosisSymptoms from 'components/teacher/DiagnosisSymptoms';
import { useParams, useHistory } from 'react-router-dom';
import Diagnosis from 'classes/Diagnosis';
import LoadingPage from 'components/misc/LoadingPage';
import { Button } from 'react-bootstrap';
import TeacherBreadcrumb from './TeacherBreadcrumb';
import CaseItem from 'classes/CaseItem';
import caseItemReducer from 'redux/reducers/caseItem.reducer';
import ButtonModal from 'components/misc/ButtonModal';
import ButtonModalCheckbox from 'components/misc/ButtonModalCheckbox';

export interface TeacherDiagnosisDetailsProps {}

const TeacherDiagnosisDetails: React.SFC<TeacherDiagnosisDetailsProps> = () => {
	const user = useSelector((state: ReduxState) => state.authReducer.user);
	const caseItem = useSelector((state: ReduxState) => state.caseItemReducer.selectedCase);
	const caseId = Number(useParams<{ caseId: string }>().caseId);
	const diagnosisId = Number(useParams<{ diagnosisId: string }>().diagnosisId);
	const [modalConfirm, setModalConfirm] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const diagnosis = useSelector((state: ReduxState) => state.diagnosisReducer.diagnoses.find((d) => d.id === diagnosisId));
	const owned = diagnosis?.user?.id === user?.id || user?.role.id === 1;

	useEffect(() => {
		Diagnosis.fetchById(diagnosisId);
		if (!caseItem) {
			CaseItem.fetchById(caseId).then((c) => dispatch(caseItemReducer.actions.setSelectedCase(c)));
		}
	}, [caseId, caseItem, diagnosisId, dispatch]);

	const handleDelete = async () => {
		await Diagnosis.remove(diagnosis.id);
		history.push(`/admin/case/${caseItem.id}`);
	};

	if (!diagnosis) return <LoadingPage />;
	return (
		<div>
			<TeacherBreadcrumb caseItem={caseItem} diagnosis={diagnosis} />
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<h1 style={{ margin: 'auto' }}>{diagnosis.name.toTitleCase()}</h1>
				<ButtonModal
					disabled={!owned}
					confirmButtonText={`Slet ${diagnosis.name}`}
					title={`Du er ved at slette ${diagnosis.name}`}
					variant="outline-danger"
					onConfirm={handleDelete}
					openButtonText="Slet"
					confirmDisabled={!modalConfirm}
				>
					<ButtonModalCheckbox checked={modalConfirm} onChange={(v) => setModalConfirm(v)}>
						Diagnosen kan ikke gendannes. Er du sikker?
					</ButtonModalCheckbox>
				</ButtonModal>
			</div>
			<StyledDivider />
			<GuideText>
				Diagnoserne er kernen i den kliniske udredning, og dermed også i vores system. Du skal angive hvilke symptomer, som diagnosen
				indeholder. Jo flere symptomer, desto mere dynamik. Kan du ikke finde det symptom du vil have, kan du oprette et nyt.
			</GuideText>
			<StyledDivider />
			<GuideText>
				Du kan føre musen over tallene for hvert symptom, for at se hvad tallet betyder. Jo højere et tal, desto større sandsynlighed vil der
				være for, at et symptom indgår som en del af de patienter, der får {diagnosis.name} som deres diagnose.
			</GuideText>
			<DiagnosisSymptoms caseItem={caseItem} diagnosis={diagnosis} />
			<StyledDivider />
			<div style={{ textAlign: 'center' }}>
				<Button size="lg" onClick={() => history.push(`/admin/case/${caseItem.id}`)}>
					Tilbage
				</Button>
			</div>
		</div>
	);
};

export default TeacherDiagnosisDetails;
