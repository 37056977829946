import React, { useState, useContext } from 'react';
import { StyledParagraph } from 'components/layout/styled/text';
import Answer from 'classes/Answer';
import { StepContext } from 'components/patient/Patient';
import { Button, Form } from 'semantic-ui-react';

export interface CaseInputProps {
	content: string;
}

const CaseInput: React.SFC<CaseInputProps> = ({ content: text }) => {
	const { step } = useContext(StepContext);
	const [input, setInput] = useState('');
	const [answering, setAnswering] = useState(false);
	const isAnswered = step.answers.some((a) => !!a.text);

	const answer = async () => {
		setAnswering(true);
		await Answer.answer({ stepId: step.id, text: input });
		setAnswering(false);
	};

	return (
		<div>
			<StyledParagraph>{text}</StyledParagraph>
			{!isAnswered && (
				<div>
					<Form>
						<Form.TextArea
							onChange={(e, { value }) => setInput(value as string)}
							value={input}
							placeholder="Omkring 150 tegn."
							autoSize={{ minRows: 5 }}
						/>
					</Form>
					<p style={{ textAlign: 'right' }}>Antal tegn: {input.length}</p>
					<Button loading={answering} disabled={input.length < 3} color="blue" onClick={answer} fluid basic>
						Videre
					</Button>
				</div>
			)}
			{isAnswered && (
				<StyledParagraph>
					<b>Du svarede: </b>
					{step.answers[0]?.text}
				</StyledParagraph>
			)}
		</div>
	);
};

export default CaseInput;
