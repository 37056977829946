import React, { useEffect } from 'react';
import { GuideText, StyledDivider, FlexRow } from './Guide';
import Actions from 'components/teacher/CaseActions';
import { useHistory } from 'react-router-dom';
import CaseItem from 'classes/CaseItem';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { Button } from 'react-bootstrap';
import Action from 'classes/Action';
import { Divider } from 'semantic-ui-react';
import utilsReducer from 'redux/reducers/utils.reducer';

export interface GuideStepsProps {}

const GuideSteps: React.SFC<GuideStepsProps> = () => {
	const history = useHistory();
	const caseItem = useSelector((state: ReduxState) => state.caseItemReducer.selectedCase);
	const dispatch = useDispatch();

	useEffect(() => {
		Action.fetchAll();
	}, []);

	const handleDelete = async (caseActionId: number) => {
		await CaseItem.deleteCaseAction(caseActionId);
	};

	return (
		<div>
			<h1 style={{ textAlign: 'center' }}>Steps</h1>
			<GuideText>
				Du skal her oprette dine steps. Steps er den rækkefølge, som opgave-løseren bliver præsenteret for
				information i, eller skal angive information.
			</GuideText>
			<StyledDivider />
			<Actions
				caseItem={caseItem}
				choices={(ca) => (
					<>
						<Button
							variant="outline-primary"
							onClick={() => history.push(`/admin/case/${caseItem.id}/step/${ca.id}`)}
						>
							Rediger
						</Button>
						<Button variant="outline-danger" onClick={() => handleDelete(ca.id)}>
							Slet
						</Button>
					</>
				)}
			/>
			<Divider />
			<FlexRow>
				<Button onClick={() => dispatch(utilsReducer.actions.changeGuideStep(1))} variant="outline-secondary">
					Tilbage
				</Button>
				<Button
					onClick={() => dispatch(utilsReducer.actions.changeGuideStep(3))}
					disabled={caseItem.actions.length === 0 || caseItem.actions.some((a) => !a.template)}
					variant="outline-secondary"
				>
					Videre
				</Button>
			</FlexRow>
		</div>
	);
};

export default GuideSteps;
