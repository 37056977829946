import React, { useState, useEffect } from 'react';
import Symptom from 'classes/Symptom';
import Parameter from 'classes/Parameter';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import Select from 'components/misc/Select';
import { Button, Form } from 'react-bootstrap';

export interface AddSymptomParameterProps {}

const AddSymptomParameter: React.SFC<AddSymptomParameterProps> = () => {
	const params = useParams<{ symptomId: string }>();
	const symptomId = Number(params.symptomId);
	const parameters = useSelector((state: ReduxState) => state.parameterReducer.parameters);
	const [parameterId, setParameterId] = useState(null);
	const [min, setMin] = useState(null);
	const [max, setMax] = useState(null);

	useEffect(() => {
		Parameter.fetchAll();
	}, []);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		await Symptom.addParameter({
			parameterId,
			min: Number(min),
			max: Number(max),
			symptomId
		});
	};

	return (
		<Form as="tr">
			<td>
				<Select
					options={parameters.map((p) => ({
						label: p.name,
						value: p.id
					}))}
					isClearable
					onChange={(value) => setParameterId(value)}
					value={parameterId}
				/>
			</td>
			<td>
				<Form.Control type="number" value={min} onChange={(e) => setMin(e.target.value)} />
			</td>
			<td>
				<Form.Control type="number" value={max} onChange={(e) => setMax(e.target.value)} />
			</td>
			<td>
				<Button type="submit" block onClick={handleSubmit} variant="outline-primary">
					Tilføj
				</Button>
			</td>
		</Form>
	);
};

export default AddSymptomParameter;
