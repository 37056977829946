import { gql } from 'apollo-boost';
import Apollo from './Apollo';
import store from 'redux/store';
import { Perspective as PerspectiveType } from 'types/generated';
import symptomReducer from 'redux/reducers/symptom.reducer';

interface Perspective extends PerspectiveType {}

class Perspective {
	static fragment = gql`
		fragment Perspective on Perspective {
			id
			name
		}
	`;

	static fetchAll = async () => {
		const query = gql`
			query {
				perspectives {
					...Perspective
				}
			}
			${Perspective.fragment}
		`;

		const perspectives = await Apollo.query<Perspective[]>('perspectives', query);
		store.dispatch(symptomReducer.actions.addPerspectives(perspectives));
	};
}

export default Perspective;
