import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import TeacherCases from './TeacherCases';
import Guide from './Guide';
import TeacherDiagnosisDetails from './TeacherDiagnosisDetails';
import { ReduxState } from 'redux/reducers';
import LoadingPage from 'components/misc/LoadingPage';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import TeacherPatient from './TeacherPatient';
import CreateCase from './CreateCase';
import TeacherSymptom from './TeacherSymptom';
import CaseActionDetails from './ActionDetails';

export const StyledContainer = styled.div`
	max-width: 1200px;
	margin: 1em auto;
	width: 100%;
`;

export interface TeacherAppProps {}

const TeacherApp: React.SFC<TeacherAppProps> = () => {
	const history = useHistory();
	const user = useSelector((state: ReduxState) => state.authReducer.user);

	useEffect(() => {
		if (user.role > 2) history.push('/');
	}, [history, user.role]);

	if (!user) return <LoadingPage />;
	if (user.role > 2) return null;
	return (
		<StyledContainer>
			<h1>{}</h1>
			<Switch>
				<Route
					path="/admin/case/:caseId/diagnosis/:diagnosisId/symptom/:symptomId"
					component={TeacherSymptom}
				/>
				<Route path="/admin/case/:caseId/diagnosis/:diagnosisId" component={TeacherDiagnosisDetails} />
				<Route path="/admin/case/:caseId/patient/:patientId" component={TeacherPatient} />
				<Route path="/admin/new" component={CreateCase} />
				<Route path="/admin/case/:caseId/step/:caseActionId" component={CaseActionDetails} />
				<Route path="/admin/case/:caseId" component={Guide} />
				<Route path="/admin" component={TeacherCases} />
			</Switch>
		</StyledContainer>
	);
};

export default TeacherApp;
