import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';

export interface SubMenusProps {
	sidebarHide: Function;
}

const SubMenus: React.SFC<SubMenusProps> = ({ sidebarHide }) => {
	const history = useHistory();
	const user = useSelector((state: ReduxState) => state.authReducer.user);

	const handleNavigation = (url: string) => {
		history.push(url);
		sidebarHide();
	};

	return (
		<>
			<Menu.Menu>
				<Menu.Item onClick={() => handleNavigation('/')}>
					<Icon name="home" />
					Dynamiske cases
				</Menu.Item>
			</Menu.Menu>
			<Menu.Menu position="right">
				{user && <Menu.Item>Velkommen {user.username.toTitleCase()}</Menu.Item>}
				{user && user.role.id < 3 && (
					<Menu.Item onClick={() => handleNavigation(`/admin`)}>
						<Icon name="wrench" />
						Underviser
					</Menu.Item>
				)}
				{!user && (
					<Menu.Item onClick={() => handleNavigation('/login')}>
						<Icon name="user" />
						Log ind
					</Menu.Item>
				)}
				{user && (
					<Menu.Item onClick={() => handleNavigation('/logout')}>
						<Icon name="x" />
						Log ud
					</Menu.Item>
				)}
			</Menu.Menu>
		</>
	);
};

export default SubMenus;
