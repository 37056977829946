import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import styled from 'styled-components';

export const Tag = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid black;
	border-radius: 8px;
	background-color: ${(props) => props.color};
	color: white;
	padding: 2px 8px;
	margin-right: 5px;
	font-size: 0.9em;
	width: fit-content;
	cursor: ${(props) => (props.onClick ? 'pointer' : null)};
`;

export interface LayoutProps {
	children: object;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
	const [windowSize, setWindowSize] = useState(window.innerWidth);

	useEffect(() => {
		window.addEventListener('resize', () => {
			setWindowSize(window.innerWidth);
		});

		return window.removeEventListener('resize', () => setWindowSize(window.innerWidth));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.innerWidth]);

	if (windowSize > 400)
		return (
			<div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
				<Header />
				{children}
				<div style={{ flexGrow: 1 }} />
				<Footer />
			</div>
		);
	return <Sidebar>{children}</Sidebar>;
};

export default Layout;
