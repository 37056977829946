import { gql } from 'apollo-boost';
import Diagnosis from 'classes/Diagnosis';
import Symptom from 'classes/Symptom';
import Parameter from 'classes/Parameter';
import Apollo from './Apollo';
import store from 'redux/store';
import { Patient as PatientType, CreatePatientInput } from 'types/generated';
import authReducer from 'redux/reducers/auth';
import Step from './Step';
import patientReducer from 'redux/reducers/patient.reducer';
import User from './User';

interface Patient extends PatientType {}

class Patient {
	static fragment = gql`
		fragment Patient on Patient {
			id
			createdAt
			updatedAt
			diagnosis {
				...Diagnosis
			}
			case {
				id
				name
			}
			symptoms {
				...Symptom
			}
			parameters {
				parameter {
					...Parameter
				}
				value
			}
			steps {
				...Step
			}
			user {
				id
				username
			}
		}
		${Diagnosis.fragment}
		${Symptom.fragment}
		${Parameter.fragment}
		${Step.fragment}
	`;

	static fetchById = async (id: number) => {
		const query = gql`
			query Patient($id: Int!) {
				patient(id: $id) {
					...Patient
				}
			}
			${Patient.fragment}
		`;

		const patient = await Apollo.query<Patient>('patient', query, { id });
		store.dispatch(authReducer.actions.addPatient(patient));
		return patient;
	};

	static removeMany = async (ids: number[]) => {
		const mutation = gql`
			mutation($ids: [Int]) {
				removePatients(ids: $ids)
			}
		`;

		await Apollo.mutate('deletePatientsBySetId', mutation, { ids });
		store.dispatch(patientReducer.actions.removePatients(ids));
	};

	static remove = async (id: number) => {
		const mutation = gql`
			mutation RemovePatient($id: Int) {
				removePatient(id: $id)
			}
		`;

		const patientId = await Apollo.mutate<number>('removePatient', mutation, { id });
		store.dispatch(authReducer.actions.removePatient(patientId));
	};

	static create = async (data: CreatePatientInput) => {
		const mutation = gql`
			mutation CreatePatient($data: CreatePatientInput) {
				createPatient(data: $data) {
					...Patient
				}
			}
			${Patient.fragment}
		`;

		const patient = await Apollo.mutate<Patient>('createPatient', mutation, { data });
		store.dispatch(authReducer.actions.addPatient(patient));
		return patient;
	};

	static hide = async (id: number) => {
		const mutation = gql`
			mutation HidePatient($id: Int) {
				hidePatient(id: $id)
			}
		`;

		await Apollo.mutate('hidePatient', mutation, { id });
		await User.fetch();
	};
}

export default Patient;
