import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Parameter from 'classes/Parameter';
import { insertOrReplace, removeFromState } from 'redux/misc/utility';

const initialState = {
	parameters: [] as Parameter[]
};

const parameterReducer = createSlice({
	name: 'parameter',
	initialState,
	reducers: {
		addParameters: (state, action: PayloadAction<Parameter | Parameter[]>) => {
			insertOrReplace(state.parameters, action.payload);
		},
		removeParameter: (state, action: PayloadAction<number>) => {
			removeFromState(state.parameters, action.payload);
		},
		clearParameters: (state) => {
			state.parameters = [];
		}
	}
});

export default parameterReducer;
