import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { insertOrReplace, removeFromState } from 'redux/misc/utility';
import Patient from 'classes/Patient';

const initialState = {
	patients: [] as Patient[]
};

const patientReducer = createSlice({
	name: 'patient',
	initialState,
	reducers: {
		addPatients: (state, action: PayloadAction<Patient | Patient[]>) => {
			insertOrReplace(state.patients, action.payload);
		},
		removePatients: (state, action: PayloadAction<number[]>) => {
			for (let id of action.payload) {
				removeFromState(state.patients, id);
			}
		}
	}
});

export default patientReducer;
