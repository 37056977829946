import React, { useEffect, useState } from 'react';
import CaseItem from 'classes/CaseItem';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { Table, Button, Spinner } from 'react-bootstrap';
import { StyledDivider } from './Guide';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import TeacherIdeaExplanation from './TeacherIdeaExplanation';
import utilsReducer from 'redux/reducers/utils.reducer';
import LoadingPage from 'components/misc/LoadingPage';

const StyledRow = styled.tr`
	:hover {
		background-color: whitesmoke;
		cursor: pointer;
	}
`;

export interface TeacherCasesProps {}

const TeacherCases: React.SFC<TeacherCasesProps> = () => {
	const [loading, setLoading] = useState(true);
	const history = useHistory();
	const user = useSelector((state: ReduxState) => state.authReducer.user);
	const caseItems = useSelector((state: ReduxState) => state.caseItemReducer.caseItems);
	const dispatch = useDispatch();

	useEffect(() => {
		CaseItem.fetchMine(user).finally(() => {
			setLoading(false);
		});
	}, []);

	const handleOpenCase = (caseId: number) => {
		dispatch(utilsReducer.actions.changeGuideStep(0));
		history.push(`/admin/case/${caseId}`);
	};

	if (loading) return <LoadingPage />;
	return (
		<div>
			<h1 style={{ textAlign: 'center' }}>Dine cases</h1>
			<div style={{ textAlign: 'right' }}>
				<Button variant="success" onClick={() => history.push(`/admin/new`)} size="lg">
					Opret case
				</Button>
			</div>
			<StyledDivider />
			<Table bordered>
				<thead>
					<tr>
						<th>Navn</th>
						<th>Diagnoser</th>
					</tr>
				</thead>
				<tbody>
					{caseItems.length === 0 && !loading && (
						<tr>
							<td style={{ textAlign: 'center' }} colSpan={2}>
								Der er ingen cases. Tilføj en oppe til højre!
							</td>
						</tr>
					)}
					{caseItems.map((c) => (
						<StyledRow key={c.id} onClick={() => handleOpenCase(c.id)}>
							<td>{c.name}</td>
							<td>{c.diagnoses.length}</td>
						</StyledRow>
					))}
					{loading && (
						<tr>
							<td style={{ textAlign: 'center' }} colSpan={2}>
								<Spinner animation="border" />
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<StyledDivider />
			<TeacherIdeaExplanation />
		</div>
	);
};

export default TeacherCases;
