import React, { useEffect, useState } from 'react';
import { GuideText, FlexRow } from './Guide';
import Patient from 'classes/Patient';
import { Table, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import CaseItem from 'classes/CaseItem';
import Parameter from 'classes/Parameter';
import { Divider } from 'semantic-ui-react';
import ButtonLoading from 'components/misc/ButtonLoading';
import utilsReducer from 'redux/reducers/utils.reducer';

interface GuidePatientParametersProps {}

const GuidePatientParameters = (props: GuidePatientParametersProps) => {
	const caseItem = useSelector((state: ReduxState) => state.caseItemReducer.selectedCase);
	const parameters = useSelector((state: ReduxState) => state.parameterReducer.parameters);
	const [loading, setLoading] = useState(false);
	const [patients, setPatients] = useState<Patient[]>([]);
	const [isRemoving, setIsRemoving] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		Parameter.fetchAll();
	}, []);

	useEffect(() => {
		setPatients(caseItem.patients.filter((p) => !p.user));
	}, [caseItem]);

	const handleRemove = async () => {
		setIsRemoving(true);
		await Patient.removeMany(patients.map((p) => p.id));
		await CaseItem.fetchById(caseItem.id);
		setIsRemoving(false);
	};

	const generate = async () => {
		setLoading(true);
		await Patient.create({ caseId: caseItem.id, nPatients: 10 });
		await CaseItem.fetchById(caseItem.id);
		setLoading(false);
	};

	return (
		<div>
			<h1>Patient parameters</h1>
			<GuideText>
				Generer dine første patienter, og vurder om understående parametre giver mening ift. din case. Det er
				ikke sikkert alle parametre er relevante for dig. De er eksempler og vil variere ift. brugernes
				patienter, samt ift. hvad du vælger af steps.
			</GuideText>
			<Divider hidden />
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<ButtonLoading loading={loading} onClick={generate}>
					Generer 10 patienter
				</ButtonLoading>
				<ButtonLoading loading={isRemoving} onClick={handleRemove} style={{ marginLeft: '1em' }}>
					Slet alle eksempel patienter
				</ButtonLoading>
			</div>
			<Divider hidden />
			<div style={{ overflowX: 'auto' }}>
				<Table bordered>
					<thead>
						<tr>
							<th>ID</th>
							<th>Diagnose</th>
							{parameters.map((p) => (
								<th key={p.id}>{p.name}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{patients.map((p) => (
							<tr key={p.id} style={{ whiteSpace: 'nowrap' }}>
								<td>{p.id}</td>
								<td>{p.diagnosis.name?.toTitleCase()}</td>
								{parameters.map((param) => {
									const parameter = p.parameters.find((par) => par.parameter.id === param.id);
									if (!parameter) return <td />;
									const referenceInterval =
										parameter.parameter.min && parameter.parameter.max
											? `(${parameter.parameter.min} - ${parameter.parameter.max})`
											: '';
									return (
										<td>
											{parameter?.value} {parameter.parameter.unit} {referenceInterval}
										</td>
									);
								})}
							</tr>
						))}
					</tbody>
				</Table>
			</div>
			<Divider />
			<FlexRow>
				<Button onClick={() => dispatch(utilsReducer.actions.changeGuideStep(0))} variant="outline-secondary">
					Tilbage
				</Button>
				<Button
					onClick={() => dispatch(utilsReducer.actions.changeGuideStep(2))}
					disabled={caseItem.patients.length === 0}
					variant="outline-secondary"
				>
					Videre
				</Button>
			</FlexRow>
		</div>
	);
};

export default GuidePatientParameters;
