import { gql } from 'apollo-boost';
import Apollo from './Apollo';
import store from 'redux/store';
import { SymptomType as SymptomTypeType } from 'types/generated';
import symptomReducer from 'redux/reducers/symptom.reducer';

interface SymptomType extends SymptomTypeType {}

class SymptomType {
	static fragment = gql`
		fragment SymptomType on SymptomType {
			id
			name
		}
	`;

	static fetchAll = async () => {
		const query = gql`
			query {
				symptomTypes {
					...SymptomType
				}
			}
			${SymptomType.fragment}
		`;

		const symptomTypes = await Apollo.query<SymptomType[]>('symptomTypes', query);
		store.dispatch(symptomReducer.actions.addSymptomTypes(symptomTypes));
	};

	static create = async (name: string) => {
		const mutation = gql`
			mutation CreateSymptomType($name: String) {
				createSymptomType(name: $name) {
					...SymptomType
				}
			}
			${SymptomType.fragment}
		`;

		const symptomType = await Apollo.mutate<SymptomType>('createSymptomType', mutation, { name });
		store.dispatch(symptomReducer.actions.addSymptomTypes(symptomType));
	};
}

export default SymptomType;
