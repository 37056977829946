import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { Table, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { sample } from 'lodash';
import Patient from 'classes/Patient';
import ButtonLoading from 'components/misc/ButtonLoading';
import CaseItem from 'classes/CaseItem';
import LoadingPage from 'components/misc/LoadingPage';

export interface PatientTableProps {}

const PatientTable: React.SFC<PatientTableProps> = () => {
	const [generatingPatient, setGeneratingPatient] = useState(false);
	const history = useHistory();
	const caseItem = useSelector((state: ReduxState) => state.caseItemReducer.selectedCase);
	const patients = caseItem?.patients.filter((p) => !!p.user) || [];

	const handleOpenPatient = (id?: number) => {
		if (!id) id = sample(patients)?.id;
		history.push(`${history.location.pathname}/patient/${id}`);
	};

	const handleGeneratePatient = async () => {
		setGeneratingPatient(true);
		try {
			await Patient.create({ caseId: caseItem.id });
			await CaseItem.fetchById(caseItem.id);
			setGeneratingPatient(false);
		} catch (error) {
			setGeneratingPatient(false);
		}
	};

	const handleDeletePatient = async (id: number) => {
		await Patient.remove(id);
		await CaseItem.fetchById(caseItem.id);
	};

	if (!caseItem) return <LoadingPage />;
	return (
		<div>
			<h3>Patienter</h3>
			<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
				<Button disabled={patients.length === 0} onClick={() => handleOpenPatient()}>
					Åben tilfældig
				</Button>
				<ButtonLoading
					disabled={caseItem.diagnoses.length === 0}
					loading={generatingPatient}
					variant="success"
					onClick={handleGeneratePatient}
				>
					Generer patient
				</ButtonLoading>
			</div>
			<Table bordered>
				<thead>
					<tr>
						<th>Id</th>
						<th>Bruger</th>
						<th>Tekst</th>
						<th>Valg</th>
					</tr>
				</thead>
				<tbody>
					{patients.map((p) => (
						<tr>
							<td>{p.id}</td>
							<td>{p.user?.username || 'Tilhører ikke bruger...'}</td>
							<td>{p.steps[0]?.content.substr(0, 10)}...</td>
							<td>
								<Button variant="outline-primary" onClick={() => handleOpenPatient(p.id)}>
									Åben
								</Button>
								<Button
									onClick={() => handleDeletePatient(p.id)}
									variant="outline-danger"
									style={{ marginLeft: '5px' }}
								>
									Slet
								</Button>
							</td>
						</tr>
					))}
					{patients.length === 0 && (
						<tr>
							<td colSpan={4}>Ingen patienter endnu...</td>
						</tr>
					)}
				</tbody>
			</Table>
		</div>
	);
};

export default PatientTable;
