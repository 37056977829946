import React, { useState } from 'react';
import { Divider } from 'semantic-ui-react';
import Symptom from 'classes/Symptom';
import LoadingPage from 'components/misc/LoadingPage';
import SymptomTextToSymptomInput from './SymptomTextToSymptomInput.';
import AddSymptomTextInput from 'components/teacher/AddSymptomTextInput';
import Select from 'react-select';
import { Table } from 'react-bootstrap';
import ButtonLoading from 'components/misc/ButtonLoading';

const precisionOptions = [
	{
		label: 1,
		value: 1
	},
	{
		label: 2,
		value: 2
	},
	{
		label: 3,
		value: 3
	},
	{
		label: 4,
		value: 4
	},
	{
		label: 5,
		value: 5
	}
];

export interface SymptomDetailsSymptomTextTableProps {
	symptom: Symptom;
}

const SymptomDetailsSymptomTextTable: React.SFC<SymptomDetailsSymptomTextTableProps> = ({ symptom }) => {
	const [deleteLoading, setDeleteLoading] = useState(false);

	const handleDelete = async (id: number) => {
		setDeleteLoading(true);
		await Symptom.removeSymptomText({ symptomTextId: id, symptomId: symptom.id });
		setDeleteLoading(false);
	};

	const handleUpdatePrecision = async (symptomTextId: number, precision: number) => {
		await Symptom.addSymptomText({
			symptomId: symptom.id,
			symptomTextId,
			precision
		});
	};

	if (!symptom) return <LoadingPage />;
	return (
		<div>
			<h1>Symptom Tekst</h1>
			<Table bordered>
				<thead>
					<tr>
						<th>Tekst</th>
						<th>Præcision</th>
						<th>Valg</th>
					</tr>
				</thead>
				<tbody>
					{symptom.symptomTexts.map((t) => (
						<tr>
							<td>{t.symptomText.text}</td>
							<td>
								<Select
									style={{ width: '100%' }}
									value={{ value: t.precision, label: t.precision }}
									placeholder="Angiv precision"
									allowClear={false}
									options={precisionOptions}
									onChange={(e: any) => handleUpdatePrecision(t.symptomText.id, e.value)}
								/>
							</td>
							<td>
								<ButtonLoading
									loading={deleteLoading}
									variant="outline-danger"
									onClick={() => handleDelete(t.symptomText.id)}
								>
									Slet
								</ButtonLoading>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<div>
				<SymptomTextToSymptomInput symptomId={symptom.id} />
				<Divider />
				<AddSymptomTextInput symptomId={symptom.id} />
			</div>
		</div>
	);
};

export default SymptomDetailsSymptomTextTable;
