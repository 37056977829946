import React from 'react';
import Symptom from 'classes/Symptom';
import Diagnosis from 'classes/Diagnosis';
import _ from 'lodash';
import Highlighter from 'react-highlighter';
import { ReduxState } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Tag } from 'components/teacher/SymptomTags';

export interface SymptomTagsPrevalenceProps {
	symptoms: Symptom[];
	diagnosis: Diagnosis;
	search: string;
	owned: boolean;
}

const SymptomTagsPrevalence: React.SFC<SymptomTagsPrevalenceProps> = ({ symptoms, diagnosis, search, owned }) => {
	const user = useSelector((state: ReduxState) => state.authReducer.user);
	const caseId = Number(useParams<{ caseId: string }>().caseId);
	const diagnosisId = Number(useParams<{ diagnosisId: string }>().diagnosisId);

	const prevalence = [
		{
			value: 1,
			description: 'Indgår aldrig'
		},
		{
			value: 2,
			description: 'Usandsynligt'
		},
		{
			value: 3,
			description: 'Nogengange'
		},
		{ value: 4, description: 'Hyppigt' },
		{ value: 5, description: 'Indgår altid' }
	];

	const handlePrevalenceChange = async (diagnosisId: number, prevalence: number, symptomId: number) => {
		const data = { prevalence, symptomId };
		await Diagnosis.updateWithPrevalence(diagnosisId, data);
	};

	const isSelectedPrevalence = (symptom: Symptom, prevalence: number) => {
		const diagnosisSymptom = _.find(diagnosis.symptoms, (s) => s.symptom.id === symptom.id);
		if (!diagnosisSymptom) return false;

		return prevalence === diagnosisSymptom.prevalence;
	};

	const editProp = (p: any, s: Symptom) =>
		owned ? { onClick: () => handlePrevalenceChange(diagnosis.id, p.value, s.id) } : { disabled: true };
	return (
		<>
			{symptoms.map((s) => (
				<Tag
					style={{
						minWidth: 100,
						padding: 5,
						border: !!diagnosis.symptoms.find((symp) => symp.symptom.id === s.id)?.prevalence
							? '1px solid black'
							: null
					}}
				>
					<div style={{ textAlign: 'center', padding: '0 0 5px' }}>
						<Highlighter search={search}>
							{s.user?.id === user.id || user.role.id === 1 ? (
								<Link to={`/admin/case/${caseId}/diagnosis/${diagnosisId}/symptom/${s.id}`}>
									{s.name?.toTitleCase() || 'Intet navn...'}
								</Link>
							) : (
								s.name?.toTitleCase() || 'Intet navn...'
							)}
						</Highlighter>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						{prevalence.map((p) => (
							<OverlayTrigger
								key={p.value}
								placement="bottom"
								overlay={<Tooltip id={String(p.value)}>{p.description}</Tooltip>}
							>
								<Tag
									color={isSelectedPrevalence(s, p.value) ? '#cce8fc' : undefined}
									{...editProp(p, s)}
								>
									{p.value}
								</Tag>
							</OverlayTrigger>
						))}
					</div>
				</Tag>
			))}
		</>
	);
};

export default SymptomTagsPrevalence;
