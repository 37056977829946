import React from 'react';
import { Menu } from 'semantic-ui-react';
import styled from 'styled-components';

const Link = styled.a`
	color: white;
	text-decoration: underline;

	:hover {
		color: white;
	}
`;

export interface FooterProps {}

const Footer: React.SFC<FooterProps> = () => {
	return (
		<Menu attached inverted color="blue">
			<Menu.Item>
				<p>
					<Link href="https://ced.au.dk" target="_blank" style={{}}>
						Centre for Educational Development (CED)
					</Link>
					, Aarhus Universitet
				</p>
			</Menu.Item>
			<Menu.Menu position="right">
				<Menu.Item>
					<p>
						Af{' '}
						<Link
							href="https://pure.au.dk/portal/da/persons/kasper-lauritzen(95761062-608a-4ed1-af05-be432208dff4).html"
							target="_blank"
						>
							Kasper Lauritzen
						</Link>
						,{' '}
						<Link
							href="https://pure.au.dk/portal/da/persons/mads-ronald-dahl(b6bb1250-0288-464e-9b49-6d3215d59b37).html"
							target="_blank"
						>
							Mads Ronald Dahl
						</Link>{' '}
						og{' '}
						<Link
							href="https://pure.au.dk/portal/da/persons/thomas-jensen(f3739755-fb40-406e-b577-af166c82442a).html"
							target="_blank"
						>
							Thomas Jensen
						</Link>
					</p>
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	);
};

export default Footer;
