import React, { useEffect, useState } from 'react';
import { Image, Divider } from 'semantic-ui-react';
import dataLogo from 'images/data_maintenance_monochromatic.svg';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Scramble from 'react-scramble';

export const CenterPage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-grow: 1000;
`;

export const FadeIn = styled.div`
	opacity: 0;
	transition: opacity 2s ease-in;
`;

interface FrontpageProps {}

const Frontpage = (props: FrontpageProps) => {
	const history = useHistory();
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setLoaded(true);
		}, 2000);
	}, []);

	return (
		<CenterPage>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(2, 1fr)',
					padding: '20px',
					flexWrap: 'wrap',
					alignItems: 'center',
					textAlign: 'center'
				}}
			>
				<div>
					<div>
						<h1 style={{ fontSize: '5em' }}>
							<Scramble
								autoStart
								text="Dynamiske cases"
								steps={[
									{
										roll: 10,
										action: '+',
										type: 'all'
									},
									{
										action: '-',
										type: 'forward'
									}
								]}
							/>
						</h1>
					</div>
					<FadeIn style={loaded ? { opacity: 1 } : { opacity: 0 }}>
						<p style={{ fontSize: '1.1em' }}>Test din kliniske viden med computer genererede patienter</p>
						<Divider hidden />
						<Divider hidden />
						<Divider hidden />
						<Button size="lg" onClick={() => history.push('/register')}>
							Registrer dig her for at komme i gang
						</Button>
					</FadeIn>
				</div>
				<div style={{ marginLeft: '5em' }}>
					<Image src={dataLogo} size="large" />
				</div>
			</div>
		</CenterPage>
	);
};

export default Frontpage;
