import { Answer as AnswerType, AnswerInput } from 'types/generated';
import { gql } from 'apollo-boost';
import Patient from './Patient';
import Apollo from './Apollo';
import store from 'redux/store';
import authReducer from 'redux/reducers/auth';

interface Answer extends AnswerType {}

class Answer {
	static fragment = gql`
		fragment Answer on Answer {
			id
			text
			createdAt
			updatedAt
		}
	`;

	static answer = async (data: AnswerInput) => {
		const mutation = gql`
			mutation Answer($data: AnswerInput) {
				answer(data: $data) {
					...Patient
				}
			}
			${Patient.fragment}
		`;

		const patient = await Apollo.mutate<Patient>('answer', mutation, { data });
		store.dispatch(authReducer.actions.addPatient(patient));
	};
}

export default Answer;
