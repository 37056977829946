import React, { useState, useEffect } from 'react';
import { Form, Divider } from 'semantic-ui-react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import { map } from 'lodash';
const kasperatorURL =
	process.env.NODE_ENV === 'production' ? 'https://kasperator.viggo.medu.au.dk' : 'https://kasperator.thjen.dk';

interface TryTextboxProps {
	patientId: number;
	setValue: Function;
	value: string;
}

const TryTextbox = ({ patientId, setValue, value }: TryTextboxProps) => {
	const [semReplaces, setSemReplaces] = useState<{ [key: string]: string[] }>({});

	useEffect(() => {
		const fetch = async () => {
			if (!patientId) return;
			const { data } = await axios.post(`${kasperatorURL}/api/getSemReplacesObj`, {
				patientId
			});

			setSemReplaces(data);
		};

		fetch();
	}, [patientId]);

	const autoText = (text: string) => {
		// GENDER:1
		text = text.replace(/( hun| han)[ ]/g, ' {{gender:1}} ');
		text = text.replace(/(Hun |Han )/g, '{{Gender:1}}  ');

		// GENDER:2
		text = text.replace(/( ham | hende )/g, ' {{gender:2}} ');

		// GENDER:3
		text = text.replace(/( hans | hendes )/g, ' {{gender:3}} ');
		text = text.replace(/(Hans |Hendes )/g, '{{Gender:3}} ');

		// GENDER:4
		text = text.replace(/( hr| fru| frk| frøken).?[ ]/g, ' {{gender:4}} ');
		text = text.replace(/(Hr|Fru|Frk|Frøken).?[ ]/g, '{{Gender:4}} ');

		// GENDER:5
		text = text.replace(/[ ](mand|kvinde|dreng|pige)[ ]/g, ' {{gender:5}} ');
		text = text.replace(/(Mand|Kvinde|Dreng|Pige)[ ]/g, '{{Gender:5}} ');

		// GENDER:6
		text = text.replace(/[ ](manden|kvinden|drengen|pigen)[ ]/g, ' {{gender:6}} ');
		text = text.replace(/(Manden|Kvinden|Drengen|Pigen)[ ]/g, '{{Gender:6}} ');

		return text;
	};

	const handleChange = (value: string) => {
		// value = autoText(value);
		setValue(value);
	};

	const handleSelect = (key: string, index: number) => {
		const newValue = `${value.trim()} {{ ${key}:${index} }}`;
		setValue(newValue);
	};

	return (
		<div>
			<Form>
				<Form.TextArea
					placeholder="Filler Text"
					autoSize={{ minRows: 5 }}
					onChange={(e, { value }) => handleChange(value as string)}
					value={value}
				/>
			</Form>
			<Divider />
			<p>
				Du kan indsætte et symptom med følgende syntaks: {`{{ symp#1; }}`}, hvor tallet angiver hvor mange
				symptomer du ønsker i træk.
			</p>
			<div>
				<Table bordered>
					<thead>
						<tr>
							<th>Value</th>
							<th>Options</th>
						</tr>
					</thead>
					<tbody>
						{map(semReplaces, (s, key) => (
							<tr>
								<td>{key}</td>
								<td>
									{s.map((example, i) => (
										<Button
											style={{ marginLeft: '5px' }}
											variant="primary"
											onClick={() => handleSelect(key, i)}
										>
											{example}
										</Button>
									))}
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</div>
		</div>
	);
};

export default TryTextbox;
