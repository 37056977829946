import React, { useState, useEffect } from 'react';
import Symptom from 'classes/Symptom';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { Dropdown } from 'semantic-ui-react';
import SymptomType from 'classes/SymptomType';
import styled from 'styled-components';
import { GuideText } from './Guide';

export interface SymptomTypeInputProps {
	symptomId: number;
	symptomType: SymptomType;
}

const HoverP = styled.span`
	cursor: pointer;

	:hover {
		color: navy;
		font-weight: bold;
	}
`;

const SymptomTypeInput: React.SFC<SymptomTypeInputProps> = ({ symptomId, symptomType }) => {
	const [loading, setLoading] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const symptomTypes = useSelector((state: ReduxState) => state.symptomReducer.symptomTypes);

	useEffect(() => {
		SymptomType.fetchAll();
	}, []);

	const handleChange = async (symptomTypeId: number) => {
		setLoading(true);
		await Symptom.addSymptomType({ symptomId, symptomTypeId });
		setIsEditing(false);
		setLoading(false);
	};

	const handleCreateSymptomType = async (name: string) => {
		setLoading(true);
		await SymptomType.create(name);
		setLoading(false);
	};

	const options = symptomTypes.map((st) => ({
		value: st.id,
		text: st.name.toTitleCase(),
		key: st.id
	}));
	const dropdown = (
		<div>
			<Dropdown
				onBlur={() => setIsEditing(false)}
				loading={loading}
				allowAdditions
				search
				onAddItem={(e, { value }) => handleCreateSymptomType(value as string)}
				selection
				basic
				placeholder="Symptom type"
				onChange={(e, { value }) => handleChange(value as number)}
				options={options}
			/>
		</div>
	);

	if (isEditing) return dropdown;
	if (symptomType)
		return (
			<div>
				<GuideText>
					<b>Type:</b> <HoverP onClick={() => setIsEditing(true)}>{symptomType.name.toTitleCase()}</HoverP>
				</GuideText>
			</div>
		);
	return dropdown;
};

export default SymptomTypeInput;
