import React, { useState } from 'react';
import { Modal, Button, ButtonProps } from 'react-bootstrap';
import ButtonLoading from './ButtonLoading';

interface ButtonModalProps extends ButtonProps {
	title: string;
	onConfirm: () => Promise<void>;
	children: any;
	confirmButtonText: string;
	style?: any;
	disabled?: boolean;
	confirmDisabled?: boolean;
	openButtonText: string;
}

const ButtonModal = ({
	title,
	onConfirm,
	children,
	style,
	confirmButtonText,
	disabled,
	openButtonText,
	confirmDisabled,
	...props
}: ButtonModalProps) => {
	const [loading, setLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const handleConfirm = async () => {
		setLoading(true);
		await onConfirm();
		setLoading(false);
	};

	return (
		<>
			<Modal show={isOpen} onHide={() => setIsOpen(false)}>
				<Modal.Header>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{children}</Modal.Body>
				<Modal.Footer>
					<ButtonLoading onClick={handleConfirm} loading={loading} disabled={confirmDisabled}>
						{confirmButtonText}
					</ButtonLoading>
					<Button onClick={() => setIsOpen(false)} variant="outline-secondary">
						Annuller
					</Button>
				</Modal.Footer>
			</Modal>
			<Button disabled={disabled} onClick={() => setIsOpen(true)} style={style} {...props}>
				{openButtonText}
			</Button>
		</>
	);
};

export default ButtonModal;
