import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { GuideText, StyledDivider } from './Guide';
import Symptom from 'classes/Symptom';
import LoadingPage from 'components/misc/LoadingPage';
import SymptomParameters from 'components/teacher/SymptomParameters';
import { Input, Divider } from 'semantic-ui-react';
import { Button } from 'react-bootstrap';
import TeacherBreadcrumb from './TeacherBreadcrumb';
import Diagnosis from 'classes/Diagnosis';
import SymptomDetailsSymptomTextTable from 'components/teacher/SymptomDetailsSymptomTextTable';
import CaseItem from 'classes/CaseItem';
import caseItemReducer from 'redux/reducers/caseItem.reducer';
import SymptomTypeInput from './SymptomTypeInput';
import ButtonModal from 'components/misc/ButtonModal';
import ButtonModalCheckbox from 'components/misc/ButtonModalCheckbox';

interface TeacherSymptomProps {}

const TeacherSymptom = (props: TeacherSymptomProps) => {
	const history = useHistory();
	const [editing, setEditing] = useState(false);
	const [fetching, setFetching] = useState(true);
	const [name, setName] = useState('');
	const dispatch = useDispatch();
	const symptomId = Number(useParams<{ symptomId: string }>().symptomId);
	const diagnosisId = Number(useParams<{ diagnosisId: string }>().diagnosisId);
	const caseId = Number(useParams<{ caseId: string }>().caseId);
	const symptom = useSelector((state: ReduxState) => state.symptomReducer.symptoms.find((s) => s.id === symptomId));
	const caseItem = useSelector((state: ReduxState) => state.caseItemReducer.selectedCase);
	const diagnosis = useSelector((state: ReduxState) => state.diagnosisReducer.diagnoses.find((d) => d.id === diagnosisId));
	const user = useSelector((state: ReduxState) => state.authReducer.user);
	const [modalConfirming, setModalConfirming] = useState(false);

	useEffect(() => {
		const fetch = async () => {
			if (!symptom) await Symptom.fetchById(symptomId);
			if (!diagnosis) await Diagnosis.fetchById(diagnosisId);
			if (!caseItem) {
				const caseItem = await CaseItem.fetchById(caseId);
				dispatch(caseItemReducer.actions.setSelectedCase(caseItem));
			}
			setFetching(false);
		};

		fetch();
	}, [caseId, caseItem, diagnosis, diagnosisId, dispatch, symptom, symptomId]);

	const handleNameChange = async () => {
		await Symptom.update(symptomId, { name: name.toLowerCase() });
		setEditing(false);
	};

	const handleRemoveSymptom = async () => {
		history.push(`/admin/case/${caseItem.id}/diagnosis/${diagnosisId}`);
		await Symptom.remove(symptomId);
	};

	if (fetching || !user) return <LoadingPage />;
	const isOwnSymptom = symptom.user?.id === user.id || user.role.id === 1;
	return (
		<div>
			<TeacherBreadcrumb caseItem={caseItem} diagnosis={diagnosis} symptom={symptom} />
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				{symptom.name && !editing && (
					<h1
						onClick={() => {
							setEditing(true);
							setName(symptom.name);
						}}
						style={{ justifyContent: 'center', cursor: 'pointer' }}
					>
						{symptom?.name.toTitleCase()}
					</h1>
				)}
				{(!symptom.name || editing) && (
					<div
						style={{
							display: 'flex',
							margin: 'auto',
							justifyContent: 'center',
							alignItems: 'center',
							width: '50%'
						}}
					>
						<Input size="large" placeholder="Angiv navn" onChange={(e) => setName(e.target.value)} value={name} />
						<Button style={{ marginLeft: '5px' }} size="lg" variant="primary" onClick={() => handleNameChange()}>
							Gem
						</Button>
						{editing && (
							<Button style={{ marginLeft: '5px' }} size="lg" variant="secondary" onClick={() => setEditing(false)}>
								Annullér
							</Button>
						)}
					</div>
				)}
				<ButtonModal
					disabled={!isOwnSymptom}
					title={`Slet ${symptom.name}`}
					confirmButtonText="Slet symptomet"
					onConfirm={handleRemoveSymptom}
					variant="outline-danger"
					openButtonText="Slet symptom"
					confirmDisabled={!modalConfirming}
				>
					<ButtonModalCheckbox checked={modalConfirming} onChange={(v) => setModalConfirming(v)}>
						Symptomet kan ikke gendannes. Er du sikker på du vil slette det?
					</ButtonModalCheckbox>
				</ButtonModal>
			</div>
			<StyledDivider />
			<SymptomTypeInput symptomId={symptom.id} symptomType={symptom.symptomType} />
			<GuideText>
				<b>Kategori:</b> {symptom.category.name}
			</GuideText>
			<br />
			<GuideText>
				<b>Perspektiv:</b> {symptom.perspective.name.toTitleCase()}
			</GuideText>
			<Divider />
			<div style={{ textAlign: 'center' }}>
				<GuideText>
					Symptomer er byggeklodserne i de dynamiske cases. Du kan her redigere parametre, som hænger sammen med symptomet (eksempelvis
					feber og temperatur, eller hyperventilation og respirationsfrekvens). Derudover kan du give symptomet en symptom kategori.
					Symptomet vil ikke kunne indgå i casen samtidig med et andet symptom i samme kategori (eksempelvis vil kategorien bevidsthed
					indeholde bevidstløshed og vågen, som ikke kan være til stede samtidigt).
				</GuideText>
			</div>
			<Divider />
			<SymptomParameters symptom={symptom} />
			<Divider />
			<GuideText>Du kan også tilføje tekst, som dynamisk vil blive importeret når symptomet er valgt hos en patient.</GuideText>
			<Divider />
			<SymptomDetailsSymptomTextTable symptom={symptom} />
			<StyledDivider />
			<div style={{ textAlign: 'center' }}>
				<Button size="lg" variant="primary" onClick={() => history.push(`/admin/case/${caseItem.id}/diagnosis/${diagnosisId}`)}>
					Færdig
				</Button>
			</div>
		</div>
	);
};

export default TeacherSymptom;
