import React, { useContext } from 'react';
import { Button, Segment, Divider } from 'semantic-ui-react';
import marked from 'marked';
import Answer from 'classes/Answer';
import { StepContext } from './Patient';

export interface CaseButtonProps {
	content: string;
}

const CaseButton: React.SFC<CaseButtonProps> = ({ content }) => {
	const { step } = useContext(StepContext);
	const values: { label: string; value: any }[] = JSON.parse(content);
	const selectedLabels = step.answers.filter((a) => a.text !== 'complete').map((a) => a.text);

	const handleAnswer = async (label: string) => {
		await Answer.answer({ stepId: step.id, text: label });
	};

	return (
		<div>
			<p style={{ textAlign: 'center' }}>
				Vælg de muligheder du vil. Overvej hvor mange du skal bruge. Du kan gå videre når du har valgt én, men
				kan vælge så mange du vil.
			</p>
			<div>
				{values.map((b) => (
					<Button style={{ float: 'left' }} onClick={() => handleAnswer(b.label)} disabled={selectedLabels.includes(b.label)}>
						{b.label}
					</Button>
				))}
<div style={{ clear: 'both'}}></div>
			</div>
			<Divider />
			<div>
				{values.map(
					(b) =>
						selectedLabels.includes(b.label) && (
							<Segment>
								{b.label}:
								<Divider />
								<div dangerouslySetInnerHTML={{ __html: marked(b.value, { smartypants: true }) }} />
							</Segment>
						)
				)}
			</div>
		</div>
	);
};

export default CaseButton;
