import React from 'react';
import styled from 'styled-components';
import { Button, Spinner, ButtonProps } from 'react-bootstrap';

const StyledButton = styled(Button)`
	transition: 2s width ease-out;
`;

interface ButtonLoadingProps extends ButtonProps {
	loading: boolean;
	children: any;
	onClick: Function;
	style?: any;
}

const ButtonLoading = ({ children, loading, disabled, ...props }: ButtonLoadingProps) => {
	return (
		<StyledButton disabled={loading || disabled} {...props}>
			{loading ? (
				<>
					<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
					<span className="sr-only">Loading...</span>
				</>
			) : (
				children
			)}
		</StyledButton>
	);
};

export default ButtonLoading;
