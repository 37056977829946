import React, { useEffect } from 'react';
import { Container, Divider } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import User from 'classes/User';
import PatientFinishedLabel from './PatientCompletedLabel';

export interface PatientListProps {}

const PatientList: React.FC<PatientListProps> = () => {
	const user = useSelector((state: ReduxState) => state.authReducer.user);
	const history = useHistory();

	useEffect(() => {
		User.fetch();
	}, []);

	return (
		<Container>
			<div style={{ textAlign: 'center' }}>
				<h2>Patienter</h2>
				<Divider />
				{user.patients.length === 0 && (
					<p style={{ fontSize: '1.1em' }}>
						Du har ingen patienter. For at tilføje en patient, skal du bruge et link som vil blive udleveret til dig.
					</p>
				)}
			</div>
			{user.patients.length > 0 && (
				<Table bordered striped hover>
					<thead>
						<tr>
							<th>ID</th>
							<th>Case</th>
							<th>Dato oprettet</th>
							<th>Besvaret</th>
						</tr>
					</thead>
					<tbody>
						{user.patients.map((p) => (
							<tr style={{ cursor: 'pointer' }} onClick={() => history.push(`/patient/${p.id}`)}>
								<td>{p.id}</td>
								<td>{p.case.name}</td>
								<td>{new Date(p.createdAt).toLocaleString()}</td>
								<td>
									<PatientFinishedLabel finished={p.steps.every((s) => s.answers.length > 0)} />
								</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td style={{ textAlign: 'center', fontSize: '1.1em' }} colSpan={4}>
								For at tilføje en patient, skal du bruge et link som vil blive udleveret til dig
							</td>
						</tr>
					</tfoot>
				</Table>
			)}
		</Container>
	);
};

export default PatientList;
