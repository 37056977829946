import React from 'react';
import { ReduxState } from 'redux/reducers';
import { useSelector } from 'react-redux';
import Step from 'classes/Step';
import PatientItem from './PatientItem';
import { Segment, Container } from 'semantic-ui-react';
import _ from 'lodash';
import PatientClass from 'classes/Patient';
import { useParams } from 'react-router-dom';
export const StepContext = React.createContext<{ step: Step; currentStep: number }>(null);

export interface PatientProps {
	patient: PatientClass;
	total?: boolean;
}

const Patient: React.SFC<PatientProps> = () => {
	const patientId = Number(useParams<{ patientId: string }>().patientId);
	const patient = useSelector((state: ReduxState) => state.authReducer.user.patients.find((p) => p.id === patientId));
	const currentStep = patient.steps.reduce((sum, step) => (step.answers.length > 0 ? sum + 1 : sum), 1);
	const answers = patient.steps.flatMap((s) => s.answers);
	const dateCompleted = new Date(answers[answers.length - 1]?.updatedAt);

	const getStep = (step: Step) => {
		if (step.action.name.toLowerCase() === 'intro') return <PatientItem.Intro content={step.content} />;
		if (step.action.name.toLowerCase() === 'input') return <PatientItem.Input content={step.content} />;
		if (step.action.name.toLowerCase() === 'tabel') return <PatientItem.Table content={step.content} />;
		if (step.action.name.toLowerCase() === 'button') return <PatientItem.Button content={step.content} />;
	};

	return (
		<Container>
			<h1 style={{ textAlign: 'center' }}>{patient.case.name.toTitleCase()}</h1>
			<p style={{ textAlign: 'center', color: 'grey' }}>
				Af {patient.user.username.toTitleCase()}
				{patient.steps.length < currentStep && (
					<>
						<br />
						{dateCompleted.toLocaleString()}
					</>
				)}
			</p>
			{_.sortBy(patient.steps, (step) => step.stepNumber)
				.filter((s, i) => i + 1 <= currentStep)
				.map((step) => (
					<Segment>
						<StepContext.Provider value={{ step, currentStep }}>{getStep(step)}</StepContext.Provider>
					</Segment>
				))}
			{patient.steps.length < currentStep && (
				<Segment>
					<h3 style={{ textAlign: 'center' }}>Du er færdig. Dine svar er gemt, og du kan lukke siden.</h3>
				</Segment>
			)}
		</Container>
	);
};

export default Patient;
