import React, { useState, useEffect } from 'react';
import { GuideText } from './Guide';
import { CaseAction } from 'types/generated';
import Select from 'components/misc/Select';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import Parameter from 'classes/Parameter';
import CaseItem from 'classes/CaseItem';
import { Table } from 'react-bootstrap';
import { Tag } from 'components/teacher/SymptomTags';
import { Divider } from 'semantic-ui-react';

interface ActionTableDetailsProps {
	caseAction: CaseAction;
}

const ActionTableDetails = ({ caseAction }: ActionTableDetailsProps) => {
	const value = JSON.parse(caseAction.template);
	const [ids, setIds] = useState(value?.map((v) => v.id) || []);
	const [prevalences, setPrevalences] = useState(value?.map((v) => v.prevalence) || []);
	const [loading, setLoading] = useState(false);
	const parameters = useSelector((state: ReduxState) => state.parameterReducer.parameters);

	useEffect(() => {
		Parameter.fetchAll();
	}, []);

	const handleChange = async (ids, prevalences) => {
		setLoading(true);
		await CaseItem.addOrUpdateStep({
			id: caseAction.id,
			template: JSON.stringify(ids.map((id, i) => ({ id, prevalence: prevalences[i] || null })))
		});
		setLoading(false);
	};

	const options = parameters.map((p) => ({
		label: p.name,
		key: p.id,
		value: p.id
	}));
	return (
		<div>
			<h1>Tabel</h1>
			<GuideText>Angiv de parametre du ønsker</GuideText>
			<Select
				isMulti
				value={ids}
				options={options}
				isLoading={loading}
				isDisabled={loading}
				onChange={(value: number[]) => {
					setIds(value);
					handleChange(value, prevalences);
				}}
			/>
			<Divider />
			<GuideText>Angiv hvor hyppigt disse parametre skal være i casen.</GuideText>
			<div style={{ height: '5px' }} />
			<Table bordered>
				<thead>
					<tr>
						<th>Parameter</th>
						<th>Hyppighed</th>
					</tr>
				</thead>
				<tbody>
					{ids.map((id, pIndex) => (
						<tr>
							<td>{parameters.find((p) => p.id === id)?.name}</td>
							<td style={{ display: 'flex' }}>
								{[...Array(5)].map((v, i) => (
									<Tag
										onClick={() => {
											const cloneArray = prevalences.slice();
											cloneArray.splice(pIndex, 1, i + 1);
											setPrevalences(cloneArray);
											handleChange(ids, cloneArray);
										}}
										color={prevalences[pIndex] === i + 1 ? '#cce8fc' : null}
									>
										{i + 1}
									</Tag>
								))}
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
};

export default ActionTableDetails;
