import React from 'react';
import { Form } from 'react-bootstrap';

interface ButtonModalCheckboxProps {
	onChange: (checked: boolean) => void;
	checked: boolean;
	children: string;
}

const ButtonModalCheckbox = ({ onChange, checked, children }: ButtonModalCheckboxProps) => {
	return (
		<Form>
			<Form.Check type="checkbox" label={children} checked={checked} onChange={() => onChange(!checked)} />
		</Form>
	);
};

export default ButtonModalCheckbox;
