import CaseDiagnosis from './CaseDiagnosis';
import CaseInput from './CaseInput';
import CaseButton from './CaseButton';
import CaseTable from './CaseTable';
import CaseIntro from './CaseIntro';

export interface PatientItemProps {}

const PatientItem = (): PatientItemProps => {
	return null;
};

PatientItem.Diagnosis = CaseDiagnosis;
PatientItem.Input = CaseInput;
PatientItem.Button = CaseButton;
PatientItem.Table = CaseTable;
PatientItem.Intro = CaseIntro;

export default PatientItem;
