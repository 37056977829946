import React from 'react';
import AddSymptomParameter from './AddSymptomParameter';
import Symptom from 'classes/Symptom';
import { Button, Table } from 'react-bootstrap';

interface SymptomParametersProps {
	symptom: Symptom;
}

const SymptomParameters = ({ symptom }: SymptomParametersProps) => {
	const handleDeleteParameter = async (parameterId: number) => {
		await Symptom.removeParameter(parameterId, symptom.id);
	};

	return (
		<div>
			<h2>Parametre</h2>
			<Table bordered>
				<thead>
					<tr>
						<th>Navn</th>
						<th>Minimumsværdi</th>
						<th>Maximumsværdi</th>
						<th>Valg</th>
					</tr>
				</thead>
				<tbody>
					{symptom.parameters.map((p) => (
						<tr>
							<td>{p.parameter.name}</td>
							<td>{p.min}</td>
							<td>{p.max}</td>
							<td>
								<Button variant="outline-danger" onClick={() => handleDeleteParameter(p.parameter.id)}>
									Slet
								</Button>
							</td>
						</tr>
					))}
				</tbody>
				<tfoot>
					<AddSymptomParameter />
				</tfoot>
			</Table>
		</div>
	);
};

export default SymptomParameters;
