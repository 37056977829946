import { gql } from 'apollo-boost';
import Apollo from './Apollo';
import store from 'redux/store';
import { Diagnosis as DiagnosisType, DiagnosisInput, DiagnosisPrevalenceInput } from 'types/generated';
import diagnosisReducer from 'redux/reducers/diagnosis.reducer';
import Symptom from './Symptom';

interface Diagnosis extends DiagnosisType {}

class Diagnosis {
	static fragment = gql`
		fragment Diagnosis on Diagnosis {
			id
			name
			symptoms {
				symptom {
					...Symptom
				}
				prevalence
			}
			user {
				id
			}
		}
		${Symptom.fragment}
	`;

	static updateWithPrevalence = async (diagnosisId: number, data: DiagnosisPrevalenceInput) => {
		const mutation = gql`
			mutation($diagnosisId: Int!, $data: DiagnosisPrevalenceInput) {
				updateDiagnosisWithPrevalence(diagnosisId: $diagnosisId, data: $data) {
					...Diagnosis
				}
			}
			${Diagnosis.fragment}
		`;

		const diagnosis = await Apollo.mutate<Diagnosis>('updateDiagnosisWithPrevalence', mutation, {
			diagnosisId,
			data
		});
		store.dispatch(diagnosisReducer.actions.addDiagnoses(diagnosis));
	};

	static fetchAll = async () => {
		const query = gql`
			{
				diagnoses {
					...Diagnosis
				}
			}
			${Diagnosis.fragment}
		`;

		const diagnoses = await Apollo.query<Diagnosis[]>('diagnoses', query);
		store.dispatch(diagnosisReducer.actions.addDiagnoses(diagnoses));
	};

	static fetchById = async (id: number) => {
		const query = gql`
			query Diagnosis($id: Int!) {
				diagnosis(id: $id) {
					...Diagnosis
				}
			}
			${Diagnosis.fragment}
		`;

		const diagnosis = await Apollo.query<Diagnosis>('diagnosis', query, { id });
		store.dispatch(diagnosisReducer.actions.addDiagnoses(diagnosis));
	};

	static create = async (data: DiagnosisInput) => {
		const mutation = gql`
			mutation($data: DiagnosisInput) {
				createDiagnosis(data: $data) {
					...Diagnosis
				}
			}
			${Diagnosis.fragment}
		`;

		const diagnosis = await Apollo.mutate<Diagnosis>('createDiagnosis', mutation, { data });
		store.dispatch(diagnosisReducer.actions.addDiagnoses(diagnosis));
		return diagnosis;
	};

	static remove = async (id: number) => {
		const mutation = gql`
			mutation($id: Int!) {
				deleteDiagnosis(id: $id)
			}
		`;

		await Apollo.mutate('deleteDiagnosis', mutation, { id });
		store.dispatch(diagnosisReducer.actions.removeDiagnosis(id));
	};

	static update = async (id: number, data: Partial<DiagnosisInput>) => {
		const mutation = gql`
			mutation($id: Int!, $data: UpdateDiagnosisInput) {
				updateDiagnosis(id: $id, data: $data) {
					...Diagnosis
				}
			}
			${Diagnosis.fragment}
		`;

		const diagnosis = await Apollo.mutate<Diagnosis>('updateDiagnosis', mutation, { id, data });
		store.dispatch(diagnosisReducer.actions.addDiagnoses(diagnosis));
	};

	static removeSymptom = async (diagnosisId: number, symptomId: number) => {
		const mutation = gql`
			mutation($diagnosisId: Int!, $symptomId: Int!) {
				removeSymptomFromDiagnosis(diagnosisId: $diagnosisId, symptomId: $symptomId) {
					...Diagnosis
				}
			}
			${Diagnosis.fragment}
		`;

		const diagnosis = await Apollo.mutate<Diagnosis>('removeSymptomFromDiagnosis', mutation, {
			diagnosisId,
			symptomId
		});
		store.dispatch(diagnosisReducer.actions.addDiagnoses(diagnosis));
	};

	static copy = async (diagnosisId: number) => {
		const mutation = gql`
			mutation DiagnosisCopy($id: Int!) {
				diagnosisCopy(id: $id) {
					...Diagnosis
				}
			}
			${Diagnosis.fragment}
		`;

		const diagnosis = await Apollo.mutate('diagnosisCopy', mutation, { id: diagnosisId });
		store.dispatch(diagnosisReducer.actions.addDiagnoses(diagnosis));
		return diagnosis;
	};
}

export default Diagnosis;
