import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from 'redux/reducers';

const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
	devTools: true
});

export default store;
