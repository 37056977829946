import React from 'react';
import TeacherDiagnosesTable from './TeacherDiagnosesTable';
import CaseItem from 'classes/CaseItem';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { Button } from 'react-bootstrap';

interface TeacherChosenDiagnosesProps {
	caseItem: CaseItem;
}

const TeacherChosenDiagnoses = ({ caseItem }: TeacherChosenDiagnosesProps) => {
	const diagnoses = useSelector((state: ReduxState) =>
		state.diagnosisReducer.diagnoses.filter((d) => caseItem.diagnoses.some((diag) => diag.id === d.id))
	);

	const handleUnpick = async (diagnosisId: number) => {
		await CaseItem.removeDiagnosis(caseItem.id, diagnosisId);
	};

	return (
		<div>
			<h2>Valgte diagnoser</h2>
			<TeacherDiagnosesTable
				caseItem={caseItem}
				diagnoses={diagnoses}
				choices={(d) => (
					<Button size="sm" variant="outline-secondary" onClick={() => handleUnpick(d.id)}>
						Fravælg
					</Button>
				)}
			/>
		</div>
	);
};

export default TeacherChosenDiagnoses;
