import React, { useState, useEffect } from 'react';
import { Button, Segment } from 'semantic-ui-react';
import { CaseAction } from 'types/generated';
import SymptomText from 'classes/SymptomText';
import { useHistory } from 'react-router-dom';
import CaseItem from 'classes/CaseItem';
import marked from 'marked';
import TryTextbox from 'components/teacher/TryTextbox';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { sample } from 'lodash';

export interface FillerTextEditorProps {
	caseAction: CaseAction;
	caseId: number;
}

const FillerTextEditor: React.SFC<FillerTextEditorProps> = ({ caseAction, caseId }) => {
	const history = useHistory();
	const caseItem = useSelector((state: ReduxState) => state.caseItemReducer.selectedCase);
	const [template, setTemplate] = useState(caseAction?.template || '');
	const [saveLoading, setSaveLoading] = useState(false);
	const [generateLoading, setGenerateLoading] = useState(false);
	const [generatedText, setGeneratedText] = useState('');
	const [samplePatient, setSamplePatient] = useState(null);

	useEffect(() => {
		setSamplePatient(sample(caseItem?.patients)?.id);
	}, [caseItem]);

	const handleSubmit = async () => {
		setGenerateLoading(true);
		const generatedText = await SymptomText.try({ caseActionId: caseAction.id, text: template });
		setGeneratedText(generatedText);
		setGenerateLoading(false);
	};

	const handleSave = async () => {
		setSaveLoading(true);
		await CaseItem.addOrUpdateStep({ id: caseAction.id, template, caseId });
		history.push(`/admin/case/${caseId}`);
	};

	return (
		<Segment>
			<div style={{ display: 'flex' }}>
				<Button
					basic
					color="yellow"
					onClick={handleSubmit}
					disabled={generateLoading}
					loading={generateLoading}
				>
					Generér tekst
				</Button>
				<Button basic color="green" onClick={handleSave} loading={saveLoading} disabled={saveLoading}>
					Gem
				</Button>
			</div>
			{generatedText && (
				<Segment>
					<div dangerouslySetInnerHTML={{ __html: marked(generatedText, { smartypants: true }) }} />
				</Segment>
			)}
			<div style={{ height: '5px' }} />
			<TryTextbox patientId={samplePatient} setValue={setTemplate} value={template} />
		</Segment>
	);
};

export default FillerTextEditor;
