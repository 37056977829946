import { gql } from 'apollo-boost';
import Apollo from './Apollo';
import store from 'redux/store';
import { Category as CategoryType } from 'types/generated';
import symptomReducer from 'redux/reducers/symptom.reducer';

interface Category extends CategoryType {}

class Category {
	static fragment = gql`
		fragment Category on Category {
			id
			name
		}
	`;

	static fetchAll = async () => {
		const query = gql`
			query {
				categories {
					...Category
				}
			}
			${Category.fragment}
		`;

		const categories = await Apollo.query<Category[]>('categories', query);
		store.dispatch(symptomReducer.actions.addCategories(categories));
	};
}

export default Category;
