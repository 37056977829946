import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import _ from 'lodash';
import { CaseAction } from 'types/generated';
import { Dropdown, Button, Icon } from 'semantic-ui-react';
import CaseItem from 'classes/CaseItem';
import { Table } from 'react-bootstrap';

export interface ActionsProps {
	caseItem: CaseItem;
	choices: (arg: CaseAction) => any;
}

const Actions: React.SFC<ActionsProps> = ({ caseItem, choices }) => {
	const [loading, setLoading] = useState(false);
	const [newActionId, setNewActionId] = useState(null);
	const actions = useSelector((state: ReduxState) => state.actionReducer.actions);
	const caseActions = caseItem.actions;
	const maxStep = _.maxBy(caseActions, (action) => action.stepNumber)?.stepNumber;

	const handleAddAction = async () => {
		setLoading(true);
		await CaseItem.addOrUpdateStep({
			actionId: newActionId,
			stepNumber: (maxStep || 0) + 1,
			caseId: caseItem.id
		});
		setNewActionId(null);
		setLoading(false);
	};

	const handleChangeStep = async (id: number, stepNumber: number) => {
		if (stepNumber < 1 || stepNumber > caseActions.length) return setLoading(false);
		await CaseItem.addOrUpdateStep({ id, stepNumber, caseId: caseItem.id });
	};

	// TODO Et step skal have et brugerdefineret navn
	return (
		<div>
			<Table bordered>
				<thead>
					<tr>
						<th>Flyt</th>
						<th>Navn</th>
						<th>Indhold</th>
						<th>Step</th>
						<th>Valg</th>
					</tr>
				</thead>
				<tbody>
					{caseActions
						.slice()
						.sort((a, b) => a.stepNumber - b.stepNumber)
						.map((a) => (
							<tr key={a.id}>
								<td>
									<Icon
										style={{ cursor: 'pointer' }}
										size="large"
										color="grey"
										name="plus circle"
										onClick={() => handleChangeStep(a.id, a.stepNumber + 1)}
									/>
									<Icon
										style={{ cursor: 'pointer' }}
										size="large"
										color="grey"
										name="minus circle"
										onClick={() => handleChangeStep(a.id, a.stepNumber - 1)}
									/>
								</td>
								<td>{a.action.name.toTitleCase()}</td>
								<td style={a.template ? null : { color: 'red' }}>{a.template?.substr(0, 70) || 'Ingen indhold'}...</td>
								<td>Step {a.stepNumber}</td>
								<td>{choices(a)}</td>
							</tr>
						))}
				</tbody>
			</Table>
			<div style={{ display: 'flex' }}>
				<Dropdown
					fluid
					search
					selection
					placeholder="Action navn"
					onChange={(e, { value }) => setNewActionId(value as number)}
					value={newActionId}
					options={actions.map((action) => ({
						key: action.id,
						value: action.id,
						text: action.name
					}))}
				/>
				<Button
					loading={loading}
					disabled={loading}
					basic
					color="blue"
					style={{ whiteSpace: 'nowrap' }}
					type="primary"
					onClick={() => handleAddAction()}
				>
					Tilføj action
				</Button>
			</div>
		</div>
	);
};

export default Actions;
