import React, { useState } from 'react';
import { Segment, Message } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import User from 'classes/User';
import { useFormik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import ButtonLoading from 'components/misc/ButtonLoading';
import Register from './Register';

export interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
	const history = useHistory();
	const formik = useFormik({
		initialValues: {
			username: '',
			password: ''
		},
		onSubmit: (values) => handleSubmit(values)
	});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [isRegistering, setIsRegistering] = useState(false);

	const handleSubmit = async (data: { username: string; password: string }) => {
		setLoading(true);
		try {
			await User.login(data);
			history.push('/');
		} catch (error) {
			setError('Brugernavn eller adgangskode er ikke korrekt');
			setLoading(false);
		}
	};

	if (isRegistering) return <Register onCancel={() => setIsRegistering(false)} />;
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '50vh'
			}}
		>
			<Segment style={{ width: '400px' }} textAlign="center">
				<h1>Dynamiske Cases</h1>
				<Form onSubmit={formik.handleSubmit}>
					<Form.Group>
						<Form.Label>Brugernavn</Form.Label>
						<Form.Control
							required
							placeholder="Brugernavn..."
							name="username"
							value={formik.values.username}
							onChange={formik.handleChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Kodeord</Form.Label>
						<Form.Control
							required
							placeholder="Kodeord"
							type="password"
							name="password"
							value={formik.values.password}
							onChange={formik.handleChange}
						/>
					</Form.Group>
					<Form.Group>
						<ButtonLoading
							loading={loading}
							variant="primary"
							style={{ width: '100%' }}
							onClick={() => formik.handleSubmit()}
							type="submit"
						>
							Login
						</ButtonLoading>
					</Form.Group>
				</Form>
				<Button onClick={() => setIsRegistering(true)}>Registrer</Button>
				{error && <Message error>{error}</Message>}
			</Segment>
		</div>
	);
};

export default Login;
