import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from './components/auth/Login';
import Frontpage from './components/Frontpage';
import Logout from './components/auth/Logout';
import Layout from 'components/layout/Layout';
import User from 'classes/User';
import { ReduxState } from 'redux/reducers';
import LoadingPage from 'components/misc/LoadingPage';
import TeacherApp from 'components/teacher/TeacherApp';
import PatientList from 'components/patient/PatientList';
import PatientLoader from 'components/patient/PatientLoader';
import CaseLoader from 'components/patient/CaseLoader';
import './App.scss';

export interface AppProps {}

const App: React.FC<AppProps> = () => {
	const [fetching, setFetching] = useState(true);
	const user = useSelector((state: ReduxState) => state.authReducer.user);

	useEffect(() => {
		User.fetch().finally(() => setFetching(false));
	}, []);

	if (fetching)
		return (
			<Layout>
				<LoadingPage />
			</Layout>
		);
	return (
		<div>
			<Layout>
				<Switch>
					{user?.role.id < 3 && <Route path="/admin" component={TeacherApp} />}
					<Route path="/logout" component={Logout} />
					<Route path="/login" component={Login} />
					<Route path="/case/:token" component={CaseLoader} />
					<Route path="/patient/:patientId" component={PatientLoader} />
					<Route path="/" component={user ? PatientList : Frontpage} />
				</Switch>
			</Layout>
		</div>
	);
};

export default App;
