import React, { useState } from 'react';
import { Table, Dropdown, Button } from 'semantic-ui-react';
import { CaseAction } from 'types/generated';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import Action from 'classes/Action';
import { Link } from 'react-router-dom';
import LoadingPage from 'components/misc/LoadingPage';
import { CenterPage } from 'components/Frontpage';

export interface CaseActionWhitelistProps {
	caseAction: CaseAction;
	diagnosisId: number;
}

const CaseActionWhitelist: React.SFC<CaseActionWhitelistProps> = ({ caseAction, diagnosisId }) => {
	const [loading, setLoading] = useState(false);
	const caseItem = useSelector((state: ReduxState) => state.caseItemReducer.selectedCase);

	const handleChange = async (value: number) => {
		setLoading(true);
		await Action.whitelistSymptom({ caseActionId: caseAction.id, symptomId: value });
		setLoading(false);
	};

	if (!caseItem) return <LoadingPage />;
	if (caseItem.diagnoses.length === 0)
		return (
			<CenterPage>
				<h1>Du skal tilføje nogle diagnoser, før du kan redigere steps</h1>
			</CenterPage>
		);
	const options = caseItem.diagnoses
		.flatMap((d) => d.symptoms)
		.filter((s) => !caseAction.whitelisted.map((s) => s.id).includes(s.symptom.id))
		.filter((value, index, self) => self.map((s) => s.symptom.id).indexOf(value.symptom.id) === index)
		.map((s) => ({ value: s.symptom.id, key: s.symptom.id, text: s.symptom.name }));
	return (
		<Table size="small">
			<Table.Header>
				<Table.HeaderCell>Symptom navn</Table.HeaderCell>
				<Table.HeaderCell>Valg</Table.HeaderCell>
			</Table.Header>
			<Table.Body>
				{caseAction.whitelisted.map((s) => (
					<Table.Row>
						<Table.Cell>
							<Link to={`/admin/case/${caseItem.id}/diagnosis/${diagnosisId}/symptom/${s.id}`}>
								{s.name.toTitleCase()}
							</Link>
						</Table.Cell>
						<Table.Cell>
							<Button onClick={() => handleChange(s.id)} basic color="red">
								Fjern
							</Button>
						</Table.Cell>
					</Table.Row>
				))}
				{caseAction.whitelisted.length === 0 && (
					<Table.Row textAlign="center">
						<Table.Cell colSpan={2}>Ingen symptomer valgt...</Table.Cell>
					</Table.Row>
				)}
				<Table.Row>
					<Table.Cell colSpan={2}>
						<Dropdown
							loading={loading}
							value={null}
							placeholder="Whitelist symptom"
							search
							selection
							fluid
							options={options}
							onChange={(e, { value }) => handleChange(value as number)}
						/>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table>
	);
};

export default CaseActionWhitelist;
