import React from 'react';
import Diagnosis from 'classes/Diagnosis';
import TeacherDiagnosesTable from './TeacherDiagnosesTable';
import CaseItem from 'classes/CaseItem';
import { Grid } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { Button } from 'react-bootstrap';

interface TeacherCopyDiagnosesProps {
	caseItem: CaseItem;
}

const TeacherCopyDiagnoses = ({ caseItem }: TeacherCopyDiagnosesProps) => {
	const user = useSelector((state: ReduxState) => state.authReducer.user);
	const publicDiagnoses = useSelector((state: ReduxState) => state.diagnosisReducer.diagnoses.filter((d) => !d.user));
	const ownDiagnoses = useSelector((state: ReduxState) =>
		state.diagnosisReducer.diagnoses.filter((d) => d.user?.id === user.id)
	);

	const handleCopyDiagnosis = async (id: number) => {
		await Diagnosis.copy(id);
	};

	return (
		<Grid columns="equal">
			<Grid.Row>
				<Grid.Column>
					<TeacherDiagnosesTable diagnoses={ownDiagnoses} caseItem={caseItem} />
				</Grid.Column>
				<Grid.Column>
					<TeacherDiagnosesTable
						diagnoses={publicDiagnoses}
						caseItem={caseItem}
						choices={(d) => (
							<Button
								size="sm"
								disabled={ownDiagnoses.some((diag) => diag.name === d.name)}
								onClick={() => handleCopyDiagnosis(d.id)}
								variant="outline-secondary"
							>
								Kopier til egne
							</Button>
						)}
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default TeacherCopyDiagnoses;
