import React from 'react';
import { Image } from 'semantic-ui-react';
import dataMaintenance from 'images/data_maintenance_monochromatic.svg';
import innovation from 'images/innovation_.svg';
import target from 'images/target.svg';
import { GuideText } from './Guide';

interface TeacherIdeaExplanationProps {}

const TeacherIdeaExplanation = (props: TeacherIdeaExplanationProps) => {
	const explanations = [
		{
			title: 'Hvad?',
			explanation:
				'Dynamiske cases udvikles af undervisere i samarbejde med kollegaer og teknisk support. En faglig relevant historie eller aktuelt forløb beskrives i tekst, billeder og data i et tidsperspektiv eller en rækkefølge (steps). I arbejdet med en case er det den studerendes faglige kompetencer, som udfordres med helt unikke scenarier af samme faglige situation.',
			imageSrc: dataMaintenance
		},
		{
			title: 'Hvorfor?',
			explanation:
				'Studenter centreret undervisning, som er praksisnært og varieret, er nogle af de elementer som dynamiske case giver pædagogisk mulighed for. De studerende har brug for at arbejde selvstændigt og kunne applisere, analysere og evaluere meningsfulde individuelle cases.',
			imageSrc: target
		},
		{
			title: 'Hvordan?',
			explanation:
				'Data som værdier, enheder eller udsagn beskrives i et interval og med en sandsynlighed. En patients respirationsfrekvens kan eksempelvis ligge mellem 5 og 15, og denne kan afhænge af om patienten er agiteret eller bevidstløs. Inden for alle fagligheder vil der kunne identificeres en række parametre i hver case, som dermed giver hver studerende et unikt datasæt.',
			imageSrc: innovation
		}
	];

	return (
		<div>
			{explanations.map((e, i) => (
				<div
					key={e.title}
					style={{
						display: 'grid',
						gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
						textAlign: 'center',
						padding: '20px',
						alignItems: 'center'
					}}
				>
					{i % 2 !== 0 && (
						<div style={{ margin: 'auto' }}>
							<Image src={e.imageSrc} size="large" />
						</div>
					)}
					<div>
						<h1 style={{ fontSize: '3em' }}>{e.title}</h1>
						<GuideText style={{ fontSize: '1.1em' }}>{e.explanation}</GuideText>
					</div>
					{i % 2 === 0 && (
						<div style={{ margin: 'auto' }}>
							<Image src={e.imageSrc} size="large" />
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default TeacherIdeaExplanation;
