import React, { useEffect, useState } from 'react';
import Patient from 'components/patient/Patient';
import { CenterPage } from 'components/Frontpage';
import PatientClass from 'classes/Patient';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import LoadingPage from 'components/misc/LoadingPage';
import TeacherBreadcrumb from './TeacherBreadcrumb';
import caseItemReducer from 'redux/reducers/caseItem.reducer';
import CaseItem from 'classes/CaseItem';

interface TeacherPatientProps {}

const TeacherPatient = (props: TeacherPatientProps) => {
	const [error, setError] = useState(false);
	const patientId = Number(useParams<{ patientId: string }>().patientId);
	const patient = useSelector((state: ReduxState) => state.authReducer.user.patients.find((p) => p.id === patientId));
	const caseItem = useSelector((state: ReduxState) => state.caseItemReducer.selectedCase);
	const dispatch = useDispatch();
	const caseId = Number(useParams<{ caseId: string }>().caseId);

	useEffect(() => {
		PatientClass.fetchById(patientId)
			.then()
			.catch((err) => setError(true));
		if (!caseItem) {
			CaseItem.fetchById(caseId).then((c) => dispatch(caseItemReducer.actions.setSelectedCase(c)));
		}
	}, [caseId, caseItem, dispatch, patientId]);

	if (error) return <CenterPage>Patienten blev ikke fundet</CenterPage>;
	return (
		<div>
			<TeacherBreadcrumb caseItem={caseItem} patient={patient} />
			{patient && <Patient total patient={patient} />}
			{!patient && <LoadingPage />}
		</div>
	);
};

export default TeacherPatient;
