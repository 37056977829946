import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Diagnosis from 'classes/Diagnosis';
import { insertOrReplace } from 'redux/misc/utility';

const initialState = {
	diagnoses: [] as Diagnosis[]
};

const diagnosisReducer = createSlice({
	name: 'diagnosis',
	initialState,
	reducers: {
		addDiagnoses: (state, action: PayloadAction<Diagnosis | Diagnosis[]>) => {
			insertOrReplace(state.diagnoses, action.payload);
		},
		removeDiagnosis: (state, action: PayloadAction<number>) => {
			const index = state.diagnoses.findIndex((diagnosis) => diagnosis.id === action.payload);
			state.diagnoses.splice(index, 1);
		}
	}
});

export default diagnosisReducer;
