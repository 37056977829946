import React, { useState } from 'react';
import { GuideText } from './Guide';
import { CaseAction } from 'types/generated';
import CaseItem from 'classes/CaseItem';
import { Table, Button } from 'react-bootstrap';
import { Tag } from 'components/teacher/SymptomTags';
import { Divider, Form, Message } from 'semantic-ui-react';

interface ActionButtonDetailsProps {
	caseAction: CaseAction;
}

interface ButtonTemplateInterface {
	label: string;
	value: string;
	prevalence: number;
}

const ActionButtonDetails = ({ caseAction }: ActionButtonDetailsProps) => {
	const value: ButtonTemplateInterface[] = JSON.parse(caseAction.template);
	const [template, setTemplate] = useState(value || []);
	const [isSubmitted, setIsSubmitted] = useState(false);

	const handleSave = async () => {
		await CaseItem.addOrUpdateStep({
			id: caseAction.id,
			template: JSON.stringify(template)
		});
		setIsSubmitted(true);
	};

	const handleChange = (value: string | number, index: number, key: keyof ButtonTemplateInterface) => {
		setIsSubmitted(false);
		const cloneArray = template.slice();
		cloneArray[index][key as any] = value;
		setTemplate(cloneArray);
	};

	const handleAddButton = () => {
		const cloneArray = template.slice();
		cloneArray.push({ label: '', value: '', prevalence: 1 });
		setTemplate(cloneArray);
	};

	const handleRemoveButton = (index: number) => {
		const cloneArray = template.slice();
		cloneArray.splice(index, 1);
		setTemplate(cloneArray);
		setIsSubmitted(false);
	};

	return (
		<div>
			<h1>Knapper</h1>
			<Divider />
			<GuideText>Angiv knapper og deres værdier.</GuideText>
			<div style={{ height: '5px' }} />
			<Table bordered>
				<thead>
					<tr>
						<th>Knap tekst</th>
						<th>Værdi</th>
						<th>Hyppighed</th>
						<th>Valg</th>
					</tr>
				</thead>
				<tbody>
					{template.map((buttonTemplate, pIndex) => (
						<tr>
							<td>
								<Form>
									<Form.TextArea
										placeholder="Knap tekst"
										value={buttonTemplate.label}
										onChange={(e, { value }) => handleChange(value as string, pIndex, 'label')}
									/>
								</Form>
							</td>
							<td>
								<Form>
									<Form.TextArea
										placeholder="Værdi"
										value={buttonTemplate.value}
										onChange={(e, { value }) => handleChange(value as string, pIndex, 'value')}
									/>
								</Form>
							</td>
							<td style={{ display: 'flex' }}>
								{[...Array(5)].map((v, i) => (
									<Tag
										onClick={() => handleChange(i + 1, pIndex, 'prevalence')}
										color={buttonTemplate.prevalence === i + 1 ? '#cce8fc' : null}
									>
										{i + 1}
									</Tag>
								))}
							</td>
							<td>
								<Button variant="outline-danger" block onClick={() => handleRemoveButton(pIndex)}>
									Slet
								</Button>
							</td>
						</tr>
					))}
					<tr>
						<td colSpan={4}>
							<Button block onClick={handleAddButton}>
								Ny knap
							</Button>
						</td>
					</tr>
					<tr>
						<td colSpan={4}>
							<Button variant="success" disabled={isSubmitted} block onClick={handleSave}>
								Gem
							</Button>
						</td>
					</tr>
				</tbody>
			</Table>
			{isSubmitted && <Message color="green">Dine knapper er gemt</Message>}
		</div>
	);
};

export default ActionButtonDetails;
