import React, { useEffect, useState } from 'react';
import PatientClass from 'classes/Patient';
import { ReduxState } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Patient from './Patient';
import { CenterPage } from 'components/Frontpage';

interface PatientLoaderProps {}

const PatientLoader = (props: PatientLoaderProps) => {
	const [error, setError] = useState(false);
	const patientId = Number(useParams<{ patientId: string }>().patientId);
	const patient = useSelector((state: ReduxState) => state.authReducer.user.patients.find((p) => p.id === patientId));

	useEffect(() => {
		PatientClass.fetchById(patientId)
			.then()
			.catch((err) => setError(true));
	}, [patientId]);

	if (error) return <CenterPage>Patienten blev ikke fundet</CenterPage>;
	return <Patient patient={patient} />;
};

export default PatientLoader;
