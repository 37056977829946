import React from 'react';
import { Tag } from 'components/layout/Layout';

interface PatientFinishedLabelProps {
	finished: Boolean;
}

const PatientFinishedLabel = ({ finished }: PatientFinishedLabelProps) => {
	if (finished) return <Tag color="green">Besvaret</Tag>;
	return <Tag color="darkred">Ikke besvaret</Tag>;
};

export default PatientFinishedLabel;
