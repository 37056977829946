import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import User from 'classes/User';
import { insertOrReplace } from 'redux/misc/utility';

const initialState = {
	users: [] as User[]
};

const usersReducer = createSlice({
	name: 'users',
	initialState,
	reducers: {
		addUsers: (state, action: PayloadAction<User | User[]>) => {
			insertOrReplace(state.users, action.payload);
		}
	}
});

export default usersReducer;
