import React, { useState } from 'react';
import SymptomText from 'classes/SymptomText';
import Symptom from 'classes/Symptom';
import { Input } from 'semantic-ui-react';
import ButtonLoading from 'components/misc/ButtonLoading';

export interface AddSymptomTextInputProps {
	symptomId?: number;
}

const AddSymptomTextInput: React.SFC<AddSymptomTextInputProps> = ({ symptomId }) => {
	const [symptomText, setSymptomText] = useState('');
	const [submitLoading, setSubmitLoading] = useState(false);

	const handleSubmit = async () => {
		setSubmitLoading(true);
		const data = { text: symptomText.toLowerCase() };
		const result = await SymptomText.create(data);
		if (symptomId && result.id) {
			const joinData = { symptomId, symptomTextId: result.id };
			await Symptom.addSymptomText(joinData);
		}
		setSymptomText('');
		setSubmitLoading(false);
	};

	return (
		<div style={{ display: 'grid', gridTemplateColumns: '80% 20%' }}>
			<Input
				value={symptomText}
				placeholder="Tilføj symptom tekst"
				onChange={(e) => setSymptomText(e.target.value)}
			/>
			<ButtonLoading loading={submitLoading} variant="outline-primary" onClick={handleSubmit}>
				Tilføj
			</ButtonLoading>
		</div>
	);
};

export default AddSymptomTextInput;
