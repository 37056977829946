import { combineReducers } from 'redux';
import authReducer from './auth';
import diagnosisReducer from './diagnosis.reducer';
import caseItemReducer from './caseItem.reducer';
import symptomReducer from './symptom.reducer';
import patientReducer from './patient.reducer';
import parameterReducer from './parameter.reducer';
import actionReducer from './action.reducer';
import usersReducer from './users.reducer';
import utilsReducer from './utils.reducer';

const rootReducer = combineReducers({
	authReducer: authReducer.reducer,
	caseItemReducer: caseItemReducer.reducer,
	diagnosisReducer: diagnosisReducer.reducer,
	symptomReducer: symptomReducer.reducer,
	patientReducer: patientReducer.reducer,
	parameterReducer: parameterReducer.reducer,
	actionReducer: actionReducer.reducer,
	usersReducer: usersReducer.reducer,
	utilsReducer: utilsReducer.reducer
});

export type ReduxState = ReturnType<typeof rootReducer>;

export default rootReducer;
