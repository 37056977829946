import React, { useState } from 'react';
import Symptom from 'classes/Symptom';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import Category from 'classes/Category';
import Perspective from 'classes/Perspective';
import styled from 'styled-components';
import { Icon, Input } from 'semantic-ui-react';

export interface SymptomTagsProps {
	symptoms: Symptom[];
	category: Category;
	perspective: Perspective;
}

export const Tag = styled.div<{ disabled?: boolean }>`
	background-color: ${(props) => props.color};
	cursor: ${(props) => (props.onClick ? 'pointer' : null)};
	padding: 2px 10px;
	border-radius: 6px;
	border: 1px solid grey;
	margin: 2px 2px 0 2px;
	width: fit-content;
	color: ${(props) => (props.disabled && !props.color ? 'lightgrey' : null)};

	:hover {
		border: 1px solid black;
	}
`;

const SymptomTags: React.SFC<SymptomTagsProps> = ({ symptoms, category, perspective }) => {
	const history = useHistory();
	const user = useSelector((state: ReduxState) => state.authReducer.user);
	const [isEditing, setIsEditing] = useState(false);
	const [name, setName] = useState('');

	const handleSymptomAdd = async (name: string) => {
		if (!user) throw new Error('You should be logged in to do this action');
		if (!name) return setIsEditing(false);
		const data = { categoryId: category.id, perspectiveId: perspective.id, name };
		await Symptom.create(data);
		setIsEditing(false);
	};

	return (
		<div style={{ display: 'flex' }}>
			{symptoms.map((s: any) => (
				<Tag color="#cce8fc" onClick={() => history.push(`/admin/symptoms/${s.id}`)}>
					{s.name.toTitleCase()}
				</Tag>
			))}
			{!isEditing && (
				<Tag
					style={{ borderStyle: 'dashed' }}
					onClick={() => {
						setIsEditing(true);
					}}
				>
					<Icon name="plus circle" />
					Tilføj symptom
				</Tag>
			)}
			{isEditing && (
				<Input
					style={{ width: 80 }}
					size="small"
					onBlur={() => handleSymptomAdd(name)}
					onChange={(e) => setName(e.target.value)}
					onPressEnter={() => handleSymptomAdd(name)}
				/>
			)}
		</div>
	);
};

export default SymptomTags;
