import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import User from 'classes/User';
import Role from 'classes/Role';
import Patient from 'classes/Patient';
import { insertOrReplace } from 'redux/misc/utility';

const initialState = {
	user: null as User | null,
	roles: [] as Role[]
};

const authReducer = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		login: (state, action: PayloadAction<User>) => {
			state.user = action.payload;
		},
		logout: (state) => {
			state.user = null;
		},
		setRoles: (state, action: PayloadAction<Role[]>) => {
			state.roles = action.payload;
		},
		addPatient: (state, action: PayloadAction<Patient>) => {
			insertOrReplace(state.user.patients, action.payload);
		},
		removePatient: (state, action: PayloadAction<number>) => {
			const index = state.user.patients.findIndex((p) => p.id === action.payload);
			if (index === -1) return;
			state.user.patients.splice(index, 1);
		}
	}
});

export default authReducer;
