import { gql } from 'apollo-boost';
import Apollo from './Apollo';
import store from 'redux/store';
import { Action as ActionType, ActionInput, WhitelistSymptomInput } from 'types/generated';
import CaseItem from './CaseItem';
import actionReducer from 'redux/reducers/action.reducer';
import caseItemReducer from 'redux/reducers/caseItem.reducer';

interface Action extends ActionType {}

class Action {
	static fragment = gql`
		fragment Action on Action {
			id
			name
		}
	`;

	static fetchAll = async () => {
		const query = gql`
			query {
				actions {
					...Action
				}
			}
			${Action.fragment}
		`;

		const actions = await Apollo.query<Action[]>('actions', query);
		store.dispatch(actionReducer.actions.addActions(actions));
	};

	static create = async (data: ActionInput) => {
		const mutation = gql`
			mutation($data: ActionInput) {
				createAction(data: $data) {
					...Action
				}
			}
			${Action.fragment}
		`;

		const action = await Apollo.mutate<Action>('createAction', mutation, { data });
		store.dispatch(actionReducer.actions.addActions(action));
	};

	static delete = async (id: number) => {
		const mutation = gql`
			mutation($id: Int!) {
				deleteAction(id: $id)
			}
		`;

		await Apollo.mutate('deleteAction', mutation, { id });
		store.dispatch(actionReducer.actions.removeAction(id));
	};

	static update = async (id: number, data: Partial<ActionInput>) => {
		const mutation = gql`
			mutation($id: Int!, $data: ActionInput) {
				updateAction(id: $id, data: $data) {
					...Action
				}
			}
			${Action.fragment}
		`;

		const action = await Apollo.mutate<Action>('updateAction', mutation, { id, data });
		store.dispatch(actionReducer.actions.addActions(action));
	};

	static whitelistSymptom = async (data: WhitelistSymptomInput) => {
		const mutation = gql`
			mutation CaseActionWhitelistSymptom($data: WhitelistSymptomInput) {
				caseActionWhitelistSymptom(data: $data) {
					...CaseItem
				}
			}
			${CaseItem.fragment}
		`;

		const caseItem = await Apollo.mutate<CaseItem>('caseActionWhitelistSymptom', mutation, { data });
		return store.dispatch(caseItemReducer.actions.addCaseItems(caseItem));
	};
}

export default Action;
