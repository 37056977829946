import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CaseItem from 'classes/CaseItem';
import { removeFromState, insertOrReplace } from 'redux/misc/utility';

const initialState = {
	caseItems: [] as CaseItem[],
	selectedCase: null as CaseItem
};

const caseItemReducer = createSlice({
	name: 'caseItem',
	initialState,
	reducers: {
		addCaseItems: (state, action: PayloadAction<CaseItem | CaseItem[]>) => {
			insertOrReplace(state.caseItems, action.payload);
			if (Array.isArray(action.payload)) {
				const index = action.payload.findIndex((c) => c.id === state.selectedCase?.id);
				if (index !== -1) state.selectedCase = action.payload[index];
			} else {
				if (state.selectedCase?.id === action.payload.id) state.selectedCase = action.payload;
			}
		},
		clearCaseItems: (state) => {
			state.caseItems = [];
		},
		removeCaseItem: (state, action: PayloadAction<number>) => {
			removeFromState(state.caseItems, action.payload);
		},
		setSelectedCase: (state, action: PayloadAction<CaseItem>) => {
			state.selectedCase = action.payload;
		}
	}
});

export default caseItemReducer;
