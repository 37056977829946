import React from 'react';
import FillerTextEditor from 'components/teacher/FillerTextEditor';
import CaseActionWhitelist from './CaseActionWhitelist';
import ChosenSymptomActions from 'components/teacher/ChosenSymptomActions';
import { useParams } from 'react-router-dom';
import { CaseAction } from 'types/generated';

interface CaseIntroDetailsProps {
	caseAction: CaseAction;
}

const CaseIntroDetails = ({ caseAction }: CaseIntroDetailsProps) => {
	const params = useParams<{ caseId: string; caseActionId: string; diagnosisId: string }>();
	const caseId = Number(params.caseId);
	const diagnosisId = Number(params.diagnosisId);

	return (
		<div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', margin: '1em' }}>
			<div style={{ flexGrow: 1 }}>
				<FillerTextEditor caseId={caseId} caseAction={caseAction} />
				<CaseActionWhitelist diagnosisId={diagnosisId} caseAction={caseAction} />
			</div>
			<div>
				<ChosenSymptomActions caseAction={caseAction} caseId={caseId} />
			</div>
		</div>
	);
};

export default CaseIntroDetails;
