import { gql } from 'apollo-boost';
import Apollo from './Apollo';
import store from 'redux/store';
import authReducer from 'redux/reducers/auth';
import { User as UserType, UserInput } from 'types/generated';
import Patient from './Patient';
import usersReducer from 'redux/reducers/users.reducer';

interface User extends UserType {}

class User {
	static fragment = gql`
		fragment User on User {
			id
			role {
				id
				name
			}
			username
			patients {
				...Patient
			}
		}
		${Patient.fragment}
	`;

	static fetch = async () => {
		const query = gql`
			query {
				user {
					...User
				}
			}
			${User.fragment}
		`;

		const user = await Apollo.query<User>('user', query);
		store.dispatch(authReducer.actions.login(user));
	};

	static login = async (data: { username: string; password: string }) => {
		const query = gql`
			query($data: LoginInput) {
				login(data: $data)
			}
		`;

		await Apollo.query('login', query, { data });
		await User.fetch();
	};

	static logout = async () => {
		const query = gql`
			query {
				logout
			}
		`;

		await Apollo.query('logout', query);
		store.dispatch(authReducer.actions.logout());
	};

	static register = async (data: UserInput) => {
		const mutation = gql`
			mutation($data: UserInput) {
				register(data: $data)
			}
		`;

		await Apollo.mutate<User>('register', mutation, { data });
		await User.fetch();
	};

	static fetchAll = async () => {
		const query = gql`
			query {
				users {
					...User
				}
			}
			${User.fragment}
		`;

		const users = await Apollo.query<User[]>('users', query);
		store.dispatch(usersReducer.actions.addUsers(users));
	};
}

export default User;
