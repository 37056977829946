import React, { useState, useEffect } from 'react';
import { Table, Icon, Input } from 'semantic-ui-react';
import { ReduxState } from 'redux/reducers';
import { useSelector } from 'react-redux';
import Diagnosis from 'classes/Diagnosis';
import SymptomTagsPrevalence from './SymptomTagsPrevalence';
import Symptom from 'classes/Symptom';
import Perspective from 'classes/Perspective';
import Category from 'classes/Category';
import { useHistory } from 'react-router-dom';
import ButtonModal from 'components/misc/ButtonModal';
import CaseItem from 'classes/CaseItem';

export interface DiagnosisSymptomsProps {
	diagnosis: Diagnosis;
	caseItem: CaseItem;
}

const DiagnosisSymptoms: React.SFC<DiagnosisSymptomsProps> = ({ diagnosis, caseItem }) => {
	const user = useSelector((state: ReduxState) => state.authReducer.user);
	const history = useHistory();
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(false);
	const [symptomName, setSymptomName] = useState('');
	const symptoms = useSelector((state: ReduxState) => state.symptomReducer.symptoms);
	const perspectives = useSelector((state: ReduxState) => state.symptomReducer.perspectives);
	const categories = useSelector((state: ReduxState) => state.symptomReducer.categories);
	const owned = diagnosis.user?.id === user.id || user.role.id === 1;

	useEffect(() => {
		Symptom.fetchAll();
		Perspective.fetchAll();
		Category.fetchAll();
	}, []);

	const handleCreateSymptom = async (categoryId: number, perspectiveId: number) => {
		try {
			setLoading(true);
			const symptom = await Symptom.create({ categoryId, perspectiveId, name: symptomName });
			history.push(`/admin/case/${caseItem.id}/diagnosis/${diagnosis.id}/symptom/${symptom.id}`);
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<Table celled>
			<Table.Header>
				<Table.HeaderCell colSpan={4}>
					<Input
						icon={<Icon name="search" />}
						iconPosition="left"
						fluid
						placeholder="Søg..."
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</Table.HeaderCell>
			</Table.Header>
			<Table.Header>
				<Table.HeaderCell>Kategori</Table.HeaderCell>
				<Table.HeaderCell>Perspektiv</Table.HeaderCell>
				<Table.HeaderCell>Symptomer</Table.HeaderCell>
				<Table.HeaderCell>Relevans</Table.HeaderCell>
			</Table.Header>
			{categories.map((c) =>
				perspectives.map((p) => (
					<Table.Row>
						<Table.Cell>{c.name}</Table.Cell>
						<Table.Cell>{p.name.toTitleCase()}</Table.Cell>
						<Table.Cell
							style={{
								display: 'flex',
								alignItems: 'center',
								whiteSpace: 'nowrap',
								flexWrap: 'wrap'
							}}
						>
							<SymptomTagsPrevalence
								diagnosis={diagnosis}
								symptoms={symptoms.filter((s) => s.category.id === c.id && s.perspective.id === p.id)}
								search={search}
								owned={owned}
							/>
							<ButtonModal
								onConfirm={() => handleCreateSymptom(c.id, p.id)}
								style={{ marginTop: '1em' }}
								variant="outline-secondary"
								disabled={loading || !owned}
								openButtonText="+ Tilføj symptom"
								confirmButtonText="Opret symptom"
								title="Opret symptom"
							>
								<Input fluid placeholder="Symptom navn..." onChange={(e) => setSymptomName(e.target.value)} />
							</ButtonModal>
						</Table.Cell>
						<Table.Cell>
							{symptoms
								.filter((s) => s.category.id === c.id && s.perspective.id === p.id)
								.reduce((sum, s) => {
									const prevalence = diagnosis.symptoms.find((symp) => s.id === symp.symptom.id)?.prevalence;
									return prevalence ? sum + prevalence : sum;
								}, 0)}
						</Table.Cell>
					</Table.Row>
				))
			)}
		</Table>
	);
};

export default DiagnosisSymptoms;
