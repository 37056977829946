import { gql } from 'apollo-boost';
import Apollo from './Apollo';
import store from 'redux/store';
import { Parameter as ParameterType, ParameterInput } from 'types/generated';
import parameterReducer from 'redux/reducers/parameter.reducer';

interface Parameter extends ParameterType {}

class Parameter {
	static fragment = gql`
		fragment Parameter on Parameter {
			id
			name
			unit
			min
			max
		}
	`;

	static fetchAll = async () => {
		const query = gql`
			query {
				parameters {
					id
					name
				}
			}
		`;

		const parameters = await Apollo.query<Parameter[]>('parameters', query);
		store.dispatch(parameterReducer.actions.addParameters(parameters));
	};

	static create = async (data: ParameterInput) => {
		const mutation = gql`
			mutation CreateParameter($data: ParameterInput) {
				createParameter(data: $data) {
					...Parameter
				}
			}
			${Parameter.fragment}
		`;

		const parameter = await Apollo.mutate('createParameter', mutation, { data });
		store.dispatch(parameterReducer.actions.addParameters(parameter));
		return parameter;
	};
}

export default Parameter;
