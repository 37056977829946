import React, { useState, useEffect } from 'react';
import { Step, Divider } from 'semantic-ui-react';
import styled from 'styled-components';
import GuideDiagnoses from './GuideDiagnoses';
import CaseOverview from './CaseOverview';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import CaseItem from 'classes/CaseItem';
import caseItemReducer from 'redux/reducers/caseItem.reducer';
import { useParams } from 'react-router-dom';
import LoadingPage from 'components/misc/LoadingPage';
import GuideSteps from './GuideSteps';
import GuidePatientParameters from './GuidePatientParameters';
import utilsReducer from 'redux/reducers/utils.reducer';

export const GuideText = styled.strong`
	font-weight: normal;
	font-size: 1.4em;
	margin-top: 0.5em;
	text-align: left;
`;

export const FlexContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 1200px;
	margin: 1em auto;
`;

export const StyledDivider = styled.div`
	height: 1em;
`;

export const FlexRow = styled.div`
	display: flex;
	flex: 1 1 auto;
	justify-content: center;
	align-items: center;

	> * {
		margin-left: 5px;
	}
`;

export interface GuideProps {}

const Guide: React.SFC<GuideProps> = () => {
	const [loading, setLoading] = useState(true);
	const caseId = Number(useParams<{ caseId: string }>().caseId);
	const dispatch = useDispatch();
	const caseItem = useSelector((state: ReduxState) => state.caseItemReducer.selectedCase);
	const guideStep = useSelector((state: ReduxState) => state.utilsReducer.guideStep);

	useEffect(() => {
		CaseItem.fetchById(caseId).then((c) => {
			dispatch(caseItemReducer.actions.setSelectedCase(c));
			setLoading(false);
		});
	}, [caseItem, dispatch, caseId]);

	const setStep = (step: number) => {
		dispatch(utilsReducer.actions.changeGuideStep(step));
	};

	if (loading) return <LoadingPage />;
	return (
		<div>
			<Step.Group size="small" fluid>
				<Step active={guideStep === 0} onClick={() => setStep(0)}>
					Diagnoser
				</Step>
				<Step active={guideStep === 1} disabled={caseItem.diagnoses.length === 0} onClick={() => setStep(1)}>
					Patient parametre
				</Step>
				<Step active={guideStep === 2} disabled={caseItem.diagnoses.length === 0} onClick={() => setStep(2)}>
					Steps
				</Step>
				<Step active={guideStep === 3} disabled={caseItem.diagnoses.length === 0} onClick={() => setStep(3)}>
					Case
				</Step>
			</Step.Group>
			<Divider hidden />
			{guideStep === 0 && <GuideDiagnoses />}
			{guideStep === 1 && <GuidePatientParameters />}
			{guideStep === 2 && <GuideSteps />}
			{guideStep === 3 && <CaseOverview />}
		</div>
	);
};

export default Guide;
