import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Action from 'classes/Action';
import { insertOrReplace, removeFromState } from 'redux/misc/utility';

const initialState = {
	actions: [] as Action[]
};

const actionReducer = createSlice({
	name: 'action',
	initialState,
	reducers: {
		addActions: (state, { payload }: PayloadAction<Action | Action[]>) => {
			insertOrReplace(state.actions, payload);
		},
		removeAction: (state, { payload }: PayloadAction<number>) => {
			removeFromState(state.actions, payload);
		}
	}
});

export default actionReducer;
