import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';

const Select = ({ value, options, onChange, menuPlacement, ...otherProps }: any) => {
	const [selectedValue, setSelectedValue] = useState(null);

	const handleChange = (value: any) => {
		let rValue: number | number[];
		if (Array.isArray(value)) {
			rValue = value.map((v) => v.value);
		} else {
			rValue = value.value;
		}

		setSelectedValue(rValue);
		onChange(rValue);
	};

	useEffect(() => {
		let rValue: any;
		if (Array.isArray(value)) {
			rValue = value.map((v) => ({
				value: v,
				label: options.find((o) => o.value === v)?.label
			}));
		} else {
			rValue = {
				value,
				label: options.find((o) => o.value === value)?.label
			};
		}

		setSelectedValue(rValue);
	}, [options, value]);

	return (
		<ReactSelect
			value={selectedValue}
			onChange={(value: any) => handleChange(value)}
			options={options}
			menuPlacement={menuPlacement || 'auto'}
			{...otherProps}
		/>
	);
};

export default Select;
