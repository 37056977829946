import React, { useState } from 'react';
import { Image, Divider, Message, Input, Button } from 'semantic-ui-react';
import logo from 'images/settings_monochromatic.svg';
import CaseItem from 'classes/CaseItem';
import { useHistory } from 'react-router-dom';
import { CenterPage } from 'components/Frontpage';

interface CreateCaseProps {}

const CreateCase = (props: CreateCaseProps) => {
	const history = useHistory();
	const [name, setName] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleCreateCase = async () => {
		if (!name) return setError('Du skal angive et navn på din case');
		setLoading(true);
		const caseItem = await CaseItem.create({ name });
		history.push(`/admin/case/${caseItem.id}`);
	};

	return (
		<CenterPage>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-around',
					flexWrap: 'wrap',
					alignItems: 'center',
					textAlign: 'center'
				}}
			>
				<div>
					<h1 style={{ fontSize: '5em' }}>Dynamiske cases</h1>
					<Divider hidden />
					<p style={{ fontSize: '1.1em' }}>
						Velkommen til Dynamiske Cases. Du vil nu blive guidet gennem oprettelse af en case.
						<br />
						Det første du skal gøre, er at give din case et navn.
					</p>
					<Divider hidden />
					<Input
						fluid
						placeholder="Angiv casens navn..."
						size="big"
						onChange={(e) => setName(e.target.value)}
						value={name}
					/>
					<Divider hidden />
					<Button loading={loading} onClick={handleCreateCase} primary size="big">
						Start
					</Button>
					{error && <Message color="red">{error}</Message>}
				</div>
				<div style={{ marginLeft: '5em' }}>
					<Image src={logo} size="large" />
				</div>
			</div>
		</CenterPage>
	);
};

export default CreateCase;
