import React from 'react';
import { StyledParagraph } from 'components/layout/styled/text';

export interface CaseDiagnosisProps {
	diagnosis: string;
}

const CaseDiagnosis: React.SFC<CaseDiagnosisProps> = ({ diagnosis }) => {
	return <StyledParagraph>Dynamiske cases har vurderet denne patient til diagnosen: {diagnosis}</StyledParagraph>;
};

export default CaseDiagnosis;
